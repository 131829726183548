export const API_VERSION="v1a"


export const Environment = {
    URL_API: "https://mp1.helpmetest.pepsico.digital/"+API_VERSION,
    URL_SOCK: "https://mp2.helpmetest.pepsico.digital/websocket/"+API_VERSION,
    JWT_URL: "https://mp1.helpmetest.pepsico.digital/"+API_VERSION+"/auth/login",
   //URL_API: "http://localhost:3081/"+API_VERSION,
   //URL_SOCK: "ws://localhost:3082/websocket/"+API_VERSION,
   //JWT_URL: "http://localhost:3081/"+API_VERSION+"/auth/login",
    MAX_ATTEMPT:60000,
    RETRY_MS:3000,
    DEFFER_DELAY:3000,
    OFFSET_DAYS:50,
    MAXPOOLSIZE:33,
    DIALOGSIZE:100,
    PING:0
}

