import {List, ListItem, Sheet, ListDivider, ListSubheader, CircularProgress, Stack, IconButton} from "@mui/joy";
import {observer} from "mobx-react-lite";
import {ListDecorator} from "../../../components/ListDecorator";
import ForumIcon from '@mui/icons-material/Forum';
import ArchiveIcon from '@mui/icons-material/Archive';
import {apiUsers} from "../../../net/rest/ApiUsers";
import * as React from 'react';
import {useTranslation} from "react-i18next";
import MyMenuItem from "./components/MyMenuItem";
import ThreePIcon from '@mui/icons-material/ThreeP';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Groups2Icon from '@mui/icons-material/Groups2';
import ctx from "../../../store/CurrentContext";
import {must_be_defined} from "../../../types/Alias";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {UserSType, UserType} from "../../../types/UserType";
import {apiTeams} from "../../../net/rest/ApiTeams";
import {TeamSType, TeamType} from "../../../types/TeamType";
import teamStore from "../../../store/TeamStore";
import {TypesOfUsers} from "../../../store/Enumerations";
import usersStore from "../../../store/UsersStore";
import dialogsStore from "../../../store/DialogsStore";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const DialogsCategs = observer(() => {

    const {t} = useTranslation();
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);




    function updateTeamsAndUserTeams(next?: () => void) {
        setLoading(true);
        apiTeams.getTeams({
            sucess: (data: TeamSType): void => {
                data.content.forEach((value: TeamType) => {
                    teamStore.addTeam(value);
                });
                next ? next() : setLoading(false);
            }, error: (data: any): void => {
                setLoading(false);
                currentInfoStore.setInfo("Current user teams " + data, "ERROR");
            }
        },
            [["pagesize", "1000"], ["pagenumber", "0"]]
        );
    }


    function updateUsersAsVar(typeOfuser: TypesOfUsers, next?: () => void) {
        usersStore
            .clearSelection()
            .deleteUsersBy((value: UserType) => value.usertypeid === typeOfuser);

        apiUsers.getUsers({
            sucess: (data: UserSType) => {
                data.content.forEach((value: UserType) => {
                    usersStore.addUser(value);
                })
                next ? next() : setLoading(false);
            },
            error: (data: any) => {
                setLoading(false);
                currentInfoStore.setInfo("Type of users " + typeOfuser + " " + data, "ERROR");

            }
        }, [["usertypeid", String(typeOfuser)], ["pagenumber", "0"], ["pagesize", "999999"]]);
    }

    function updateCurrentUsers() {
        ctx.localUser = usersStore.getUserByID(Number(ctx.usid));
        setLoading(false);
    }
    //SyncUser

    React.useEffect(() => {
        updateTeamsAndUserTeams(
            () => updateUsersAsVar(TypesOfUsers.Agents, () => updateUsersAsVar(TypesOfUsers.Bots, () => updateCurrentUsers()))
        );
        return () => {

        };
    }, [refresh]);


    return (<>

        <Sheet
            sx={{
                width: 190,
                height: 'calc(100dvh - var(--Button-height))',
                display: 'flex',
                flexDirection: 'column',

            }}
        >
            <ListDecorator >
                <List size="sm" >

                    <MyMenuItem thissection={{section: "ITEM", item: "ALL"}} capt={t("ChatsAll")}
                        tail={dialogsStore.tailPreSetSection}
                        icon={<ForumIcon />} />

                    <ListDivider />
                    {/* <MyMenuItem thissection={{section: "ITEM", item: "NEW"}} capt={t("ChatsNew")}
                        tail={dialogsStore.tailPreSetSection}
                        icon={<AnnouncementIcon />} />
                    <ListDivider /> */}
                    <MyMenuItem thissection={{section: "ITEM", item: "MY"}} capt={t("ChatsMy")}
                        tail={dialogsStore.tailPreSetSection}
                        icon={<ThreePIcon />} />
                    <ListItem nested>
                        <ListSubheader variant="plain" >{t("ChatsTeams")}</ListSubheader>

                        {loading && (
                            <CircularProgress color="primary" />
                        )}
                        {(!loading) && (ctx.localUser !== undefined) && (ctx.localUser?.teams.length > 0) &&
                            (<>
                                <List>
                                    {
                                        ctx.localUser?.teams.map((value: must_be_defined) => {
                                            return (
                                                <MyMenuItem thissection={{section: "KEY", item: value.teamid}} capt={value.teamds}
                                                    tail={dialogsStore.tailPreSetSection}
                                                    icon={<Groups2Icon />} />
                                            )
                                        })
                                    }
                                </List>
                            </>)
                        }
                    </ListItem>
                    <ListDivider />
                    <MyMenuItem thissection={{section: "ITEM", item: "ARH"}} capt={t("ChatsArh")}
                        tail={dialogsStore.tailPreSetSection}
                        icon={<ArchiveIcon />} />
                </List>
            </ListDecorator>
        </Sheet>

    </>);
});
export {DialogsCategs};

