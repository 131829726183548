import {Chip, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Typography} from "@mui/joy";
import dialogsStore, {PreSetSection} from "../../../../store/DialogsStore";
import {observer} from "mobx-react-lite";
import ctx from "../../../../store/CurrentContext";


const MyMenuItem = observer((props: {
    thissection: PreSetSection,
    tail: string,
    capt: string,
    icon: React.ReactElement
}) => {

    let news: {news: boolean, count: number} = {news: false, count: 0};

    (() => {
        if (props.thissection.section === "KEY") {

            news = dialogsStore.hasTeamNews(Number(props.thissection.item));

        }
        else {
            if (props.thissection.item === "ALL") {
                news = dialogsStore.hasAny();
            } else if (props.thissection.item === "MY") {
                news = dialogsStore.hasUserNews(ctx.localUser);
            }
        }

    })();

    return (
        <ListItem
            variant="plain"
            key={props.thissection.item}
            sx={{
                backgroundColor: ((dialogsStore.DialogPreSetSection.item === props.thissection.item)
                    ? "primary.softHoverBg" : "inherit")
            }}>
            <ListItemButton variant="plain"
                onClick={() => dialogsStore.DialogPreSetSection = props.thissection}
                color={(dialogsStore.DialogPreSetSection.item === props.thissection.item) ? "primary" :
                    (news.news ? "primary" : "neutral")}
            >
                <ListItemDecorator>{props.icon}</ListItemDecorator>
                <ListItemContent> <Typography color={(news.news ? "primary" : "neutral")} level="body-sm"
                    startDecorator={
                        (news.news) &&
                        (<Chip color="primary" variant="solid" size="sm" sx={{fontSize: "12px"}}>{news.count}</Chip>)
                    }
                >

                    {props.capt}
                </Typography>
                </ListItemContent>
            </ListItemButton>


        </ListItem>
    );
}
);
export default MyMenuItem;