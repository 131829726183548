import {makeAutoObservable} from "mobx";
import {UserType} from "../types/UserType";
import {TeamType} from "../types/TeamType";


class UsersStore {

    private $users: UserType[] = [];
    private $select_Name: string = "";
    private $selected_iduser: number = -1;

    public constructor() {
        makeAutoObservable(this);
    }

    /**Добавляет пользователя в Store */
    public addUser(value: UserType) {
       let i: number = this.$users.findIndex((valueF: UserType) => valueF.userid === value.userid);
        //Только актив
        if (value.active) {
            if (i >= 0) {
                this.$users[i] = {...value};
            } else {
                //this.$users.push(value);
                this.$users.push(value);
            }
        }

    }

    /** возвращает список пользователей */
    public getUsers(): UserType[] {
        return this.$users;
    }


    /** возвращает список пользователей по списку id
     * используется фильтрация массива там где у элемента id в списке ids переданной в сигнатуре
    */
    public getUsersByIDs(ids_list: number[]): UserType[] {
        return this.$users.filter((value: UserType) => {
            return ids_list.indexOf(value.userid) >= 0;
        });
    }

    public getUserByID(id: number): UserType | undefined {
        const user = this.getUsersByIDs([id]);
        return user.length === 0 ? undefined : user[0];
    }

    /** возвращает список пользователей по списку names
     * используется фильтрация массива 
    */
    public getUsersByNames(names_list: string[]): UserType[] {
        return this.$users.filter((value: UserType) => {
            return names_list.indexOf(value.userds) >= 0;
        });

    }

    public getUserByName(name: string | any): UserType | undefined {
        const user = this.getUsersByNames([name]);
        return user.length === 0 ? undefined : user[0];
    }


    /** возвращает список пользователей входящих в команды, используется фильтрация 
     * с насильственным прерывание цикла foreach если нашлось хоть одно совпадение 
    */
    public getUsersByТeamsIDs(id_teams_list: number[]): UserType[] {
        return this.$users.filter((value) => {
            let found: boolean = false;
            if ((value.teams) && (value.teams.length > 0)) {

                let ForceBreakForEach = {};
                try {
                    value.teams.forEach((team) => {
                        if (id_teams_list.indexOf(Number(team.teamid)) >= 0)
                            throw ForceBreakForEach;
                    });
                }
                catch (e) {
                    if (e === ForceBreakForEach) found = true;
                }
            }
            return found;
        }).sort((a, b) => +a.userid - +b.userid);
    }

    public getUsersByТeams(teams_list: TeamType[]): UserType[] {
        let id_teams_list: number[] = teams_list.map(value => value.teamid);
        return this.getUsersByТeamsIDs(id_teams_list);
    }


    /** чистит список 
    */
    public clearUsers(): UsersStore {
        this.clearSelection();
        this.$users = [];
        return this;

    }


    public clearSelection(): UsersStore {
        this.$select_Name = "";
        this.$selected_iduser = -1;
        return this;
    }



    /** удаляет пользователей 
    */
    public deleteUsers(users: UserType[]): UsersStore {
        users.forEach((value: UserType) => {
            let i: number = this.$users.findIndex((valueF: UserType) => valueF.userid === value.userid);
            if (i >= 0) {
                this.$users.splice(i, 1);
            }
        });
        return this;
    }

    public deleteUsersBy(predicate: (value: UserType) => boolean): UsersStore {
        this.$users.forEach((value: UserType, index: number, thisArray: UserType[]) => {
            if (predicate(value)) {
                thisArray.splice(index, 1);
            }
        });
        return this;
    }


    public deleteUser(user: UserType): UsersStore {
        let i: number = this.$users.findIndex((valueF: UserType) => valueF.userid === user.userid);
        if (i >= 0) {
            this.$users.splice(i, 1);
        }
        return this;
    };


    public addTeamToUser(userid: number, teamData: TeamType) {
        const user = this.getUserByID(userid);
        if (user !== undefined) {
            const usTeam = user.teams.find((value) => value.teamid === teamData.teamid)
            if (usTeam === undefined) {
                user.teams = [...user.teams, {teamid: teamData.teamid, teamds: teamData.teamds}]
            }
        }

    }
    public deleteTeamFromUser(userid: number, teamData: TeamType) {

        const user = this.getUserByID(userid);
        if (user !== undefined) {
            const usTeamIndex = user.teams.findIndex((value) => value.teamid === teamData.teamid)
            if (usTeamIndex >= 0) {
                user.teams.splice(usTeamIndex, 1);
            }
        }
    }
    //display
    public Display(predicate?: (value: UserType) => boolean): UserType[] {
        if (!predicate) {
            return this.$users.slice().sort((a, b) => +a.userid - +b.userid);
        }
        else {
            return this.$users.filter(predicate).sort((a, b) => +a.userid - +b.userid);
        }
    }

    //ids
    public get SelectedName() {return this.$select_Name}
    public set SelectedName(value: string) {this.$select_Name = value}



    public get SelectedIdUser(): number {return this.$selected_iduser;}
    public get SelectedNameUser(): string {

        const u = this.$users.find((value) => {return value.userid === this.$selected_iduser});
        return (u === undefined) ? "" : u.userds;

    }

    public set SelectedIdUser(value: number) {this.$selected_iduser = value;}


}


const usersStore = new UsersStore();
export default usersStore;








