import {makeAutoObservable} from "mobx";
import {UserSType, UserType} from "../types/UserType";
import Cookies from 'universal-cookie'
import {Environment} from "../environment";
import {genericClient} from "../net/rest/GenericClient";
import {apiUsers} from "../net/rest/ApiUsers";




export enum ContextNames {
     JETTON_COOKIE = "pepjetton",
     JETTON_HEAD = "PEPJetton",
     JETTON_ID = "usid",
     JWTSFA_COOKIE = "jwt",
     JWTSFA_HEAD = "PEPBackjwt",


}





export enum ContextState {
    LOADING,
    READY,
    ERROR,
    SECERROR
}

export type ContextStateExt = {
    state: ContextState,
    extratext: string[]
}
type ContextStateValue = number

class CurrentContext {

    public constructor() {
        makeAutoObservable(this);
    }

    private $usid: number | undefined = undefined;
    private $name: string | undefined = undefined;
    private $type: number | undefined = undefined;
    private $active: number | undefined = undefined;
    private $pepjetton: string | undefined = undefined;

    public get token(): string {
        return this.$pepjetton ? this.$pepjetton : "NO SET";
    }

    public get name(): string | undefined {
        return this.$name;
    }

    public get usid(): number | undefined {
        return this.$usid;
    }

    public get type(): number | undefined {
        return this.$type;
    }
    public get active(): number | undefined {
        return this.$active;
    }

    private loadCookieString(key: string, def?: string | undefined): string | undefined {
        let value = (new Cookies()).get(key);
        if (value === undefined) value = null;
        return (value != null) ? value : def;
    }

    private loadCookieNumber(key: string, def?: number | undefined): number | undefined {
        let value = (new Cookies()).get(key);
        if (value === undefined) value = null;
        return (value != null) ? value : def;
    }

    private saveCookie(key: string, value: string) {
        (new Cookies()).set(key, value, {path: "/", maxAge: (60 * 60 * 24 * 5)});
    }

    private deleteCookie(key: string) {
        (new Cookies()).remove(key)
    }

    public resetContext() {
        this.$usid = undefined; this.deleteCookie(ContextNames.JETTON_ID);
        this.$name = undefined; this.deleteCookie("name");
        this.$type = undefined; this.deleteCookie("type");
        this.$active = undefined; this.deleteCookie("active");
        this.$pepjetton = undefined; this.deleteCookie(ContextNames.JETTON_COOKIE);

    }

    /*
     загрузка сохраненных данных их кук
    */
    private logginOffline(tail: string[]): ContextStateExt {
        this.$usid = this.loadCookieNumber(ContextNames.JETTON_ID);
        if (this.$usid !== undefined) {
            if (this.$usid === 0)
                this.$usid = undefined
        }
        // Удаляем все так как без этого бесмысленно
        if (this.$usid === undefined) {
            console.log("[Context] usid is null -> clear all cookies ");
            this.resetContext();
            return {state: ContextState.SECERROR, extratext: [...tail, "сохраненный id пользователя не найден"]};
        } else {
            this.$pepjetton = this.loadCookieString(ContextNames.JETTON_COOKIE);
            if (this.$pepjetton !== undefined) {
                if (this.$pepjetton.trim().length < 10)
                    this.$pepjetton = undefined
            }
            if (this.$pepjetton === undefined) {
                console.log("[Context] pepjetton is null -> clear all cookies ");
                this.resetContext();
                return {state: ContextState.SECERROR, extratext: [...tail, "сохраненный ключ пользователя не найден"]};
            }
        }
        this.$name = this.loadCookieString("name");
        this.$type = this.loadCookieNumber("type");
        this.$active = this.loadCookieNumber("active");
        console.log("[Context] load cookies ");
        return {state: ContextState.READY, extratext: [...tail]};
    }


    /*
    Алгоритм
    verify = false - запрос не будет отправлен
    verify =true - запрос будет отправлен- >
        {
            На сервере порядок проверки:
                ищем JWTSFA_COOKIE
                    находим проверяем
                     не находим ищем JWTSFA_HEAD
                        находим проверяем

            Если запрос вернул = 200 удаляем локальную JWTSFA_COOKIE
            и заполняем куки по пользователю
        
            pepjwt- можеть быть:
                    string - какоето значение, не важно, есть или нет JWTSFA_COOKIE
                    undefined - проверка выключена
                    null - JWTSFA_HEAD все рано что тут,  JWTSFA_COOKIE должна быть заполнена


        }    
*/
    private logginOnine(pepjwt: string | undefined | null, result: (result: ContextStateExt) => void) {
        let verify = false;

        if (pepjwt === null) {
            const jwt = this.loadCookieString(ContextNames.JWTSFA_COOKIE)
            if (jwt) {
                pepjwt = jwt;
                verify = true;
            }
            else {
                pepjwt = undefined;
                verify = false;
            }
        }
        else {
            if (pepjwt !== undefined) {
                verify = true;
            }
        }
        if (verify) {
            genericClient.callHttpWait(
                Environment.JWT_URL, "POST",
                [['Content-Type', 'application/json'],
                [ContextNames.JWTSFA_HEAD, pepjwt ? pepjwt : ""]
                ],
                undefined,
                JSON.stringify(
                    {
                        usid: "N/A"
                    }
                )
            )
                .then((data: any) => {
                    const jetton=data[ContextNames.JETTON_HEAD];
                    if (jetton!==undefined) {

                        this.deleteCookie(ContextNames.JWTSFA_COOKIE);

                        this.saveCookie(ContextNames.JETTON_ID, String(this.$usid = data.usid));
                        this.saveCookie("name", this.$name = data.name);
                        this.saveCookie("type", String(this.$type = data.type));
                        this.saveCookie("active", String(this.$active = data.active));
                        this.saveCookie(ContextNames.JETTON_COOKIE, 
                            this.$pepjetton = data[ContextNames.JETTON_HEAD]);
                        result({state: ContextState.READY, extratext: []});
                    } else{
                        result({state: ContextState.SECERROR, extratext: ["ошибка формирования ключа доступа"]});
                    }
                })
                .catch((error: any) => {

                    result({state: ContextState.SECERROR, extratext: ["ошибка проверки SFA ключа " + error]});
                })
        }
        else {
            result({state: ContextState.SECERROR, extratext: ["локальный SFA ключ не найден"]});
        }
    }

    /*
        Проверка на серере существования пользователя по usid
        и в случае успеха обновление кук,в случе не нахождения пользоватлея очистка кук, ошибка просто выборос
    */
    private checkCookieByOnline(usid: string, tail: string[], check: (checkRes: ContextStateExt) => void) {
        genericClient.callHttpWaitPrepred(
            apiUsers.getOneUserPrepared(usid)
        ).then((data: UserSType) => {

            if (data.content.length > 0) {
                this.saveCookie(ContextNames.JETTON_ID, String(this.$usid = data.content[0].userid));
                this.saveCookie("name", this.$name = data.content[0].userds);
                this.saveCookie("type", String(this.$type = data.content[0].usertypeid));
                this.saveCookie("active", String(this.$active = data.content[0].active));
                check({state: ContextState.READY, extratext: []});
            }
            else {
                this.resetContext();
                check({state: ContextState.SECERROR, extratext: [...tail, "пользователь не найден в базе"]});
            }

        })
            .catch((error: any) => {
                check({state: ContextState.ERROR, extratext: [...tail, "ошибка проверки пользователя в базе " + error]});
            })

    }

    /*
      Алгоритм проверки,
      logginOnine:проверям по JWTTOKEN и JWTCOOKIE на сервере
          если их нет или проверка не успешная
              logginOfflineД:проверяем локальные куки
                  если они есть
                      checkCookieByOnline:проверяем пользователя на сервере
                          есть ок то ContextState=READY
                          если нет ContextState!=READY

   
  */
    public intitContext(pepjwt: string | undefined | null,
        callback: (result: ContextStateExt) => void) {

        this.logginOnine(pepjwt, ((result: ContextStateExt) => {

            if (result.state !== ContextState.READY) {

                const cookiesState = this.logginOffline(result.extratext);

                if (cookiesState.state === ContextState.READY) {

                    this.checkCookieByOnline(String(this.$usid), cookiesState.extratext, (checkRes: ContextStateExt) => {
                        callback(checkRes)
                    })
                }
                else
                    callback(cookiesState)
            }
            else
                callback(result);
        }));
    }

    private $localUser: UserType | undefined = undefined;

    private resetLocalUser() {
        this.localUser = undefined;
    }

    public get localUser(): UserType | undefined {
        return this.$localUser;
    }
    public set localUser(value: UserType | undefined) {
        this.$localUser = value;
    }




}
const ctx = new CurrentContext();
export default ctx;


