import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import AvatarWithStatus from "../../../../components/AvatarWithStatus";
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {observer} from 'mobx-react-lite';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, Button, ModalDialog, Sheet, Divider, DialogActions, Autocomplete, ModalClose, CircularProgress} from '@mui/joy';
import Box from '@mui/joy/Box';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import {useTranslation} from 'react-i18next';
import {apiDialogs} from '../../../../net/rest/ApiDialogs';
import dialogsStore from '../../../../store/DialogsStore';
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from '../../../../components/EditDialog';
import {identity, ustring} from '../../../../types/Alias';
import {UserType} from '../../../../types/UserType';
import {Priority, TypesOfUsers} from '../../../../store/Enumerations';
import usersStore from '../../../../store/UsersStore';
import teamStore from '../../../../store/TeamStore';
import {TeamType} from '../../../../types/TeamType';
import {DialogType} from '../../../../types/DialogType';
import currentInfoStore from '../../../../store/CurrentInfoStore';
import ctx from '../../../../store/CurrentContext';



const MsgsHead = observer(() => {
  const {t} = useTranslation();
  //Dialogs state
  const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

  //Dialogs close
  function closeDialog() {
    setDlgDescriptor((reset: EditDialogDescriptor) => {
      return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
    });
  }

  //Dialogs show
  function showDialog() {
    setDlgDescriptor((reset: EditDialogDescriptor) => {
      reset.visible = true;
      return {...reset};
    });
  }
  //Dialogs process result
  function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
    closeDialog();
    // TODO
    if (isapplied) {

      switch (anchor) {
        case 'AssigneedUser':
          apiDialogs.updateDialog(
            {
              sucess: (data: DialogType) => {
                dialogsStore.addDialogPreserve(data);
              },
              error: (data: any) => {
                currentInfoStore.setInfo("Redirect user/bot " + data, "ERROR");
              },
            },
            JSON.stringify(
              {
                dialogid: dialogsStore.Selected?.dialogid,
                assigneeid: Number(result?.get("UserID"))
              }
            )
          )
          break;
        case 'AssigneedTeam':
          apiDialogs.updateDialog(
            {
              sucess: (data: DialogType) => {
                dialogsStore.addDialogPreserve(data);
              },
              error: (data: any) => {
                currentInfoStore.setInfo("Redirect team " + data, "ERROR");
              },
            },
            JSON.stringify(
              {
                dialogid: dialogsStore.Selected?.dialogid,
                teamid: Number(result?.get("TeamID"))
              }
            )
          )
          break;
          case 'Priority':
            apiDialogs.updateDialog(
              {
                sucess: (data: DialogType) => {
                  dialogsStore.addDialogPreserve(data);
                },
                error: (data: any) => {
                  currentInfoStore.setInfo("Priority " + data, "ERROR");
                },
              },
              JSON.stringify(
                {
                  dialogid: dialogsStore.Selected?.dialogid,
                  priority: Number(result?.get("Priorityid"))
                }
              )
            )
            break;
        default:
          break;
      }

    }
  }

  
  function redirect(reDirect: number[]) {
    if (dialogsStore.Selected) {
      dlgDescriptor.title = t("Redirect_Add_Title");

      if (reDirect.length > 0) {
        dlgDescriptor.elements = [
          {
            format: "SELECT", key: "UserID", title: t((reDirect.indexOf(TypesOfUsers.Agents)>=0?"Agent":"Bot")), 
            currentValue: String(dialogsStore.Selected.assignee?.userid), source: [
              ...usersStore.Display((value) => reDirect.indexOf(value.usertypeid) >= 0).map<{code: string, value: string}>((item: UserType) => {return {code: String(item.userid), value: item.userds}})]
          }]
        dlgDescriptor.anchor = "AssigneedUser";
      } else {
        dlgDescriptor.elements = [
          {
            format: "SELECT", key: "TeamID", title: t("Team"), 
            currentValue: String(dialogsStore.Selected.team.teamid), source: [
              ...teamStore.getTeams().map<{code: string, value: string}>((item: TeamType) => {return {code: String(item.teamid), value: item.teamds}})]
          }]
        dlgDescriptor.anchor = "AssigneedTeam";
      }
      showDialog();
    }
  }


  function priority() {
    if (dialogsStore.Selected) {
      dlgDescriptor.title = t("Priority_Title");
        dlgDescriptor.elements = [
          {
            format: "SELECT", key: "Priorityid", title: t("Priority"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
            currentValue: String(dialogsStore.Selected.priorityid),
            source: [
                {code: String(Priority.Cricical), value: t("en_priority_" + Priority.Cricical)},
                {code: String(Priority.Hight), value: t("en_priority_" + Priority.Hight)},
                {code: String(Priority.Medium), value: t("en_priority_" + Priority.Medium)},
                {code: String(Priority.Low), value: t("en_priority_" + Priority.Low)},

            ]
            }]
        dlgDescriptor.anchor = "Priority";
    
      showDialog();
    }
  }

  // function addDialog() {
  //   apiDialogs.createDialog(
  //     {
  //       sucess: (data: DialogType) => {
  //         console.log("ok")
  //       },
  //       error: (data: any) => {
  //         currentInfoStore.setInfo(data, "ERROR");
  //       },
  //     },
  //     JSON.stringify(
  //       {
  //         userid: "614650"
  //       }
  //     )
  //   )
  // }
  

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          backgroundColor: 'background.body',
        }}
        py={{xs: 2, md: 2}}
        px={{xs: 1, md: 2}}
      >
        <Stack direction="row" spacing={{xs: 1, md: 2}} alignItems="center">
          <IconButton
            variant="plain"
            color="neutral"
            size="sm"
            sx={{
              display: {xs: 'inline-flex', sm: 'none'},
            }}

          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <Avatar size="lg"><AvatarWithStatus online={true} usertype={TypesOfUsers.Agents}
            src={""}
            role={"AGENT"} /></Avatar>
          <div>
            <Typography
              fontWeight="lg"
              fontSize="lg"
              component="h2"
              noWrap
            >
              {ctx.name}
            </Typography>
            <Typography level="body-sm">ID {ctx.usid}</Typography>
          </div>

        </Stack>
        <Stack spacing={1} direction="row" alignItems="center">


          <Button color='primary' variant='outlined' size='sm' startDecorator={<PrecisionManufacturingIcon />}
            //sx={{display: {xs: 'unset', sm: 'unset'}}}
            onClick={() => {
              redirect([TypesOfUsers.Bots, TypesOfUsers.BotFather]);
            }}
          >{t("RedirectBot")}
          </Button>

          <Button color='primary' variant='outlined' size='sm' startDecorator={<ReplyIcon sx={{transform: "rotate(180deg)"}} />}
            //sx={{display: {xs: 'unset', sm: 'unset'}}}
            onClick={() => {
              redirect([TypesOfUsers.Agents]);
            }}
          >{t("RedirectAgent")}</Button>
          <Button color='primary' variant='outlined' size='sm' startDecorator={<ReplyAllIcon sx={{transform: "rotate(180deg)"}} />}
            //sx={{display: {xs: 'unset', sm: 'unset'}}}
            onClick={() => {
              redirect([]);
            }}
          >{t("RedirectTeam")}</Button>
            <Button color='primary' variant='outlined' size='sm' startDecorator={<LowPriorityIcon sx={{transform: "rotate(180deg)"}} />}
            //sx={{display: {xs: 'unset', sm: 'unset'}}}
            onClick={() => {
              priority();
            }}
          >{t("Priority_Title")}</Button>
           {/* <Button color='primary' variant='outlined' size='sm' startDecorator={<LowPriorityIcon sx={{transform: "rotate(180deg)"}} />}
            //sx={{display: {xs: 'unset', sm: 'unset'}}}
            onClick={() => {
              addDialog();
            }}
          >{"addDialog"}</Button> */}
        </Stack>
      </Stack>
      <EditDialog descriptor={dlgDescriptor} />



    </Box>
  );
});
export default MsgsHead;
