import {Group, KeyboardArrowRight} from "@mui/icons-material";
import {List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Sheet} from "@mui/joy";
import {observer} from "mobx-react-lite";
import teamStore from "../../../store/TeamStore";
import {TeamType, TeamSType} from "../../../types/TeamType";
import * as React from 'react';
import {apiTeams} from "../../../net/rest/ApiTeams";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {useTranslation} from "react-i18next";
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import {identity, ustring} from "../../../types/Alias";
import {EditButtonsPanel} from "../../../components/EditButtonsPanel";
import {CaptionPanel} from "../../../components/CaptionPanel";
import {ListDecorator} from "../../../components/ListDecorator";
import {TypesOfUsers} from "../../../store/Enumerations";
import usersStore from "../../../store/UsersStore";
import {UserSType, UserType} from "../../../types/UserType";
import {apiUsers} from "../../../net/rest/ApiUsers";


const LeftPanel = observer(() => {

    const {t} = useTranslation();

    //Dialogs state
    const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

    //Dialogs close
    function closeDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
        });
    }


    //Dialogs show
    function showDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            reset.visible = true;
            return {...reset};
        });
    }


    //Dialogs process result
    function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
        closeDialog();
        // TODO
        if (isapplied) {

            switch (anchor) {
                case "ADD":

                    if (!result?.get("teamName")) {
                        currentInfoStore.setInfo("Введите имя для пользователя", "ERROR");
                        break;
                    }
                    let newUserAudit = teamStore.getTeamByName(result?.get("teamName"));
                    if (newUserAudit !== undefined) {
                        currentInfoStore.setInfo("Пользователь с именем " + result?.get("teamName") + " уже существует", "ERROR");
                        break;
                    }
                    setShowStatusLeft(true);

                    const teamTemplate = {
                        teamds: result?.get("teamName") || "NoName",
                    }

                    apiTeams.createTeam(

                        {
                            sucess: (data: TeamType) => {
                                setShowStatusLeft(false);
                                teamStore.addTeam(data)

                            },
                            error: (data: any) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Teams " + data, "ERROR");
                            },
                        },
                        JSON.stringify(teamTemplate)
                    );
                    break;
                case "UPD":
                    if (!result?.get("teamName")) {
                        currentInfoStore.setInfo("Для наименования команды нет изменений", "ERROR");
                        break;
                    }
                    let changeTeamAudit = teamStore.getTeamByName(result?.get("teamName"));
                    if (changeTeamAudit !== undefined) {
                        currentInfoStore.setInfo("Команда с именем " + result?.get("teamName") + " уже существует", "ERROR");
                        break;
                    }
                    const selecledTeam = teamStore.getTeamByID(teamStore.SelectedIdteam);
                    if (selecledTeam) {

                        setShowStatusLeft(true);

                        const teamTemplate = {
                            teamid: selecledTeam.teamid,
                            teamds: result?.get("teamName") || "NoName",
                        }

                        apiTeams.renameTeam(
                            {
                                sucess: (data: TeamType) => {
                                    setShowStatusLeft(false);
                                    selecledTeam.teamds = data.teamds

                                },
                                error: (data: any) => {
                                    setShowStatusLeft(false);
                                    currentInfoStore.setInfo("Teams " + data, "ERROR");
                                },

                            },
                            JSON.stringify(teamTemplate)
                        );
                    }

                    break;
                case "DEL":
                    const deletingTeam = teamStore.getTeamByID(teamStore.SelectedIdteam);
                    if (deletingTeam != undefined) {
                        apiTeams.deleteTeam(

                            {
                                sucess: (data: any,) => {
                                    setShowStatusLeft(false);

                                    teamStore.deleteTeam(deletingTeam);
                                },
                                error: (data: any) => {
                                    setShowStatusLeft(false);
                                    currentInfoStore.setInfo("Teams " + data, "ERROR");
                                },
                            },
                            [["teamid", String(deletingTeam.teamid)]]
                        );
                    }

                    break;
                default:
                    break;
            }
        }
    }

    //Adding
    function addRecord() {
        dlgDescriptor.title = "Add record";
        dlgDescriptor.text = "Adding new team";
        dlgDescriptor.apply = t("Button_ADD");
        dlgDescriptor.cancel = t("Button_Cancel");
        dlgDescriptor.elements = [
            {format: "INPUT", key: "teamName", title: t("Elements_Name_Title")},
        ];
        dlgDescriptor.anchor = "ADD";
        showDialog();
    }

    //Updating
    function updateRecord() {
        const selecledTeam = teamStore.getTeamByID(teamStore.SelectedIdteam);
        if (selecledTeam) {
            dlgDescriptor.title = t("Edit_Title");
            dlgDescriptor.text = t("Edit_Text");
            dlgDescriptor.apply = t("Button_change");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.elements = [{format: "INPUT", key: "teamName", title: t("Elements_Name_Title"), currentValue: selecledTeam.teamds}];
            dlgDescriptor.anchor = "UPD";
            showDialog();
        }
    }

    //Deleting
    function deleteRecord() {
        const selecledTeam = teamStore.getTeamByID(teamStore.SelectedIdteam);
        if (selecledTeam) {
            dlgDescriptor.title = t("Delete_Title");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.apply = t("Button_Delete");
            dlgDescriptor.text = `${t("Delete_Text")} ${selecledTeam.teamds} ?`;
            dlgDescriptor.elements = [];
            dlgDescriptor.anchor = "DEL";
            showDialog();
        }
    }

    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [showStatusLeft, setShowStatusLeft] = React.useState<boolean>(true);


    function updateTeams() {
        setShowStatusLeft(true);
        teamStore.clearTeams();
        apiTeams.getTeams({
            sucess: (data: TeamSType) => {

                data.content.forEach((value: TeamType) => {
                    teamStore.addTeam(value);
                })
                updateUsersAsVar(TypesOfUsers.Agents, () => updateUsersAsVar(TypesOfUsers.Bots))
            },
            error: (data: any) => {
                setShowStatusLeft(false);
                currentInfoStore.setInfo("Teams " + data, "ERROR");

            }
        },
            [["pagesize", "99999"], ["pagenumber", "0"]]
        );
    }

    function updateUsersAsVar(typeOfuser: TypesOfUsers, next?: () => void) {
        usersStore
            .clearSelection()
            .deleteUsersBy((value: UserType) => value.usertypeid === typeOfuser);

        apiUsers.getUsers({
            sucess: (data: UserSType) => {
                data.content.forEach((value: UserType) => {
                    usersStore.addUser(value);
                })
                next ? next() : setShowStatusLeft(false);
            },
            error: (data: any) => {
                setShowStatusLeft(false);
                currentInfoStore.setInfo("Type of users " + typeOfuser + " " + data, "ERROR");

            }
        }, [["usertypeid", String(typeOfuser)], ["pagenumber", "0"], ["pagesize", "999999"]]);
    }



    React.useEffect(() => {
        updateTeams();
        return () => {

        };
    }, [refresh]);


    return (
        <>
            <Sheet
                sx={{
                    width: 300,
                    height: 'calc(100dvh - var(--Header-height))',
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >

                <CaptionPanel caption={t("title_teams")}
                    status={() => showStatusLeft}
                    refresh={() => setRefresh(!refresh)} />



                <ListDecorator>
                    <List size="sm" >
                        {
                            teamStore.getTeams().map((value: TeamType) =>
                            (<ListItem
                                variant={((teamStore.SelectedIdteam === value.teamid)) ? "outlined" : "plain"}
                                key={value.teamid}
                                sx={{
                                    backgroundColor: (teamStore.SelectedIdteam === value.teamid) ? "primary.softHoverBg" : "inherit",

                                }} >

                                <ListItemButton variant="plain"
                                    onClick=
                                    {() => {
                                        teamStore.SelectedIdteam = value.teamid;
                                    }} >
                                    <ListItemDecorator><Group /></ListItemDecorator>
                                    {/* <ListItemContent>{value.nameds} ({value.members.length})</ListItemContent> */}
                                    <ListItemContent>{value.teamds}</ListItemContent>
                                    {(teamStore.SelectedIdteam === value.teamid) && (<KeyboardArrowRight />)}
                                </ListItemButton>
                            </ListItem>
                            ))}
                    </List>
                </ListDecorator>
                <EditButtonsPanel add={addRecord} update={updateRecord} delete={deleteRecord} />
            </Sheet >
            <EditDialog descriptor={dlgDescriptor} />
        </>

    );
});

export {LeftPanel};
