import * as React from 'react';
import Badge from '@mui/joy/Badge';
import Avatar, {AvatarProps} from '@mui/joy/Avatar';
import {Typography} from '@mui/joy';
import {TypesOfMessages, TypesOfUsers} from '../store/Enumerations';



type AvatarWithStatusProps = AvatarProps & {
  online?: boolean;
  usertype: TypesOfUsers | undefined;

};

export default function AvatarWithStatus(props: AvatarWithStatusProps) {
  const {online = false, usertype} = props;
  return (
    <div>
      <Badge
        color={online ? 'primary' : 'neutral'}
        variant={'solid'}
        size="sm"
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        badgeInset="6px 6px"
      >
        <Avatar size="sm"
          {...(usertype === TypesOfUsers.Bots) ? {src: '../avatars/bot.png'} :
            ((usertype === TypesOfUsers.BotFather) ? {src: '../avatars/bot.png'} :
              ((usertype === TypesOfUsers.Agents) ? {src: '../avatars/agent.png'} :
                ((usertype === TypesOfUsers.Users) ? {src: '../avatars/user.png'} : {})))
          }
        />
      </Badge>

    </div>
  );
}
