import {RestCallback, genericClient} from "./GenericClient";

class ApiTeamsUsers {
    

    api:string=genericClient.setUrl( "/api/teamusers");

    public addUserToTeam(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api,"POST",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public deleteUserFromTeam(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }



}
const apiTeamsUsers = new ApiTeamsUsers();

export {apiTeamsUsers};