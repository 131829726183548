import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import {observer} from 'mobx-react-lite';
import * as colors from './Colors';
import {CircularProgress, IconButton, List, ListItem, ListItemButton} from '@mui/joy';
import {Reply, Download} from '@mui/icons-material';
import {StoredMessageType} from '../../../../store/MessagesStore';
import messagesStore from '../../../../store/MessagesStore';
import {MsTodate, Subsctruct, UTCTodate} from '../../../../utils';
import {CandidateState, TypesOfMessages} from '../../../../store/Enumerations';
import currentInfoStore from '../../../../store/CurrentInfoStore';
import {useTranslation} from 'react-i18next';
import {apiDocs} from '../../../../net/rest/ApiDocs';
import dialogsStore from '../../../../store/DialogsStore';
import ctx from '../../../../store/CurrentContext';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

function textOfCandidate(candidateState: number | undefined) {
    if (candidateState) {
        return (candidateState === CandidateState.SENDING) ?
            "(отправка...)" :
            ((candidateState === CandidateState.QUEUING) ?
                "(в очереди ...)" :
                ((candidateState === CandidateState.WATING) ?
                    "(подтверждение ...)" :
                    ((candidateState === CandidateState.ERROR) ?
                        "(ошибка)" : "")))
    }
    else return "";
}

function MeNoMeCSS(me: boolean) {
    return me ? {
        p: 1.25,
        borderRadius: 'lg',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 'lg',
        backgroundColor: colors.backBubleMe

    } : {
        p: 1.25,
        borderRadius: 'lg',
        borderTopRightRadius: 'lg',
        borderTopLeftRadius: 0,
        backgroundColor: colors.backBubleThem

    };
}

function MeNoMeText(me: boolean) {
    return me ? {
        color: colors.fontBubleMe,
        // overflowWrap: "break-word",
        overflowWrap: "anywhere"
    } : {
        color: colors.fontBubleThem,
        // overflowWrap: "break-word",
        overflowWrap: "anywhere"
    };
}




export const BubbleMessage = observer((props: {
    message: StoredMessageType
    setReplyUUID: (value: string | undefined) => void;
}) => {

    const {t} = useTranslation();

    const {message, setReplyUUID} = props;

    const me = messagesStore.isMe(message);
    const messageDate = MsTodate(message.senttime);
    const [fileLoading, setfileLoading] = React.useState<Map<number, undefined>>(new Map());

    function RepMsgSet() {
        if (dialogsStore.eligibleForUser(ctx.localUser, dialogsStore.Selected)) {
            setReplyUUID(message.uuid);
        }
    }

    function download(docid: number, docds: string) {
        if (document.getElementById('blob_link')) {
            setfileLoading(map => {
                const newmap = new Map(map);
                newmap.set(docid, undefined);
                return newmap;
            });
            apiDocs.downloadDocFile(docid)
                .then((data: any) => {
                    const blob_link = document.getElementById('blob_link') as any;
                    if (blob_link) {
                        const url = window.URL.createObjectURL(data);
                        blob_link.href = url;
                        blob_link.download = docds;
                        blob_link?.click();
                        window.URL.revokeObjectURL(url);
                        setfileLoading(map => {
                            const newmap = new Map(map);
                            newmap.delete(docid);
                            return newmap;
                        });
                    }

                })
                .catch((error: any) => {
                    currentInfoStore.setInfo(t("GetDocerror") + error, "ERROR");
                })
        }

    }

    return (
        <>
            <Box sx={{maxWidth: '75%', minWidth: 'auto'}}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{mb: 0.25}}>

                    <Typography level="body-sm">

                        {(!me) ? Subsctruct(message.sender.userds, 25) : ""}

                    </Typography>

                    <Typography level="body-xs">
                        {messageDate}
                        {(me) && ((message.candidate !== CandidateState.NO)) && (
                            <Typography sx={{fontStyle: 'italic', fontWeight: 'light', typography: 'body2'}}>
                                {textOfCandidate(message.candidate)}
                            </Typography>

                        )}
                    </Typography>
                    <IconButton
                        onClick={RepMsgSet}
                        sx={{
                            "--IconButton-size": "21px"
                        }}
                    >
                        <Reply />
                    </IconButton>
                </Stack>
                <Box
                    sx={{position: 'relative'}}>
                    <Sheet
                        color={me ? 'primary' : 'neutral'}
                        variant={me ? 'solid' : 'soft'}
                        sx={{...(MeNoMeCSS(me))}}
                    >
                        {(message?.replymessageid != null)
                            && (
                                <Box
                                    sx={{position: 'relative'}}
                                >
                                    <Sheet
                                        color={'primary'}
                                        variant={'solid'}
                                        sx={{...(MeNoMeCSS(!me))}}
                                    >
                                        <Typography
                                            startDecorator={<FormatQuoteIcon fontSize='small' />}
                                            endDecorator={<FormatQuoteIcon fontSize='small' />}
                                            level="body-xs"
                                            sx={{...MeNoMeText(!me)}}
                                        >
                                            {message?.replycontent}
                                        </Typography>
                                    </Sheet>
                                </Box>
                            )}

                        <Typography
                            level="body-sm"
                            sx={{...MeNoMeText(me)}}>
                            {message.content}
                        </Typography>
                        {((message?.buttons) &&
                            // eslint-disable-next-line eqeqeq
                            ((message.messagetypeid != TypesOfMessages.OPTION))) &&
                            (
                                <List>
                                    {message?.buttons.map((option) => {
                                        return (
                                            <>
                                                <ListItem sx={{
                                                    borderRadius: 2, mb: 0.5, p: 0.5,
                                                    border: 1, borderColor: colors.fontBubleThem

                                                }} key={option.botstepid}>
                                                    <ListItemButton disabled>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 13,
                                                                backgroundColor: colors.backBubleThem,
                                                                color: colors.fontBubleThem,
                                                                overflowWrap: "anywhere"

                                                            }}>
                                                            {option.botstepds}
                                                        </Typography>

                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )
                                    })}
                                </List>
                            )}
                        {message?.attachments && (
                            <>
                                <Stack>
                                    <Typography fontSize="sm" level="title-sm" sx={{
                                        color: me
                                            ? colors.fontBubleMe
                                            : colors.fontBubleThem
                                    }}>{t("attached")}</Typography>

                                    {message?.attachments?.map((atch: {docds: string, docid: number}) => {

                                        return (
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontSize: 13,
                                                        backgroundColor: me
                                                            ? colors.backBubleMe
                                                            : colors.backBubleThem,
                                                        color: me
                                                            ? colors.fontBubleMe
                                                            : colors.fontBubleThem,
                                                        fontStyle: 'italic'

                                                    }} key={atch.docid}
                                                    fontSize={"13px"} endDecorator={
                                                        (fileLoading.has(atch.docid))
                                                            ? (<CircularProgress sx={{zoom: "50%"}} />)
                                                            : (<IconButton variant='plain' size='sm' onClick={() => download(atch.docid, atch.docds)}><Download /></IconButton>)

                                                    }
                                                >
                                                    {atch.docds}</Typography>
                                            </>
                                        )
                                    })}
                                </Stack>
                            </>
                        )}
                    </Sheet>
                </Box>
            </Box >
            <a id="blob_link" style={{display: 'none'}}></a>
        </>
    );
}
)



