import AttachMsg from "../../types/AttachMsgType";
import {RestCallback, genericClient} from "./GenericClient";

class ApiDocs {

    api:string=genericClient.setUrl( "/api/docs");
    uploadapi:string=genericClient.setUrl( "/upload/doc");
    downloadapi:string=genericClient.setUrl( "/api/doc");
    //http://localhost:8281/api/doc?docid=57
    
    public getDocs(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[],callback,undefined,undefined,queryparams);
    }

   
    public deleteDoc(callback:RestCallback, docid:number)
    {
        genericClient.callHttp(this.downloadapi,"DELETE",[],callback,undefined,undefined,[["docid",String(docid)]]);
    }


    public postDocAttach (callback:RestCallback, file: AttachMsg[],parts?:[string, string][])
    {
        genericClient.callHttpPostFile2(this.uploadapi, "POST", callback, file,parts);
    }
   
 
    public downloadDocFile(docid:number)
    {
        return genericClient.callHttpWaitBlob(this.downloadapi,"GET",[["docid",String(docid)]]);
    }
    
}
const apiDocs = new ApiDocs();

export {apiDocs};
