enum Priority {
  Cricical = 1,
  Hight = 2,
  Medium = 3,
  Low = 4,

}

enum TypesOfUsers {
  BotFather = 1,
  Agents = 2,
  Users = 3,
  Bots = 4,
  Unknown = -1
}

enum DialogStatus {
  Open = 1,
  Close = 2
}

enum CloseReasonStatus {
  Resolved = 1,
  Timeout = 2
}

// 1	"Решено"
// 2	"Закрыт по таймауту"

// 1	"Открыт"
// 2	"Закрыт"

// 1	"Критичный"
// 2	"Высокий"
// 3	"Средний"
// 4	"Низкий"


const CandidateState={
  NO :0,
  SENDING:1,
  WATING:2,
  QUEUING:3,
  ERROR:4
}

const TypesOfMessages={
  TEXT:0,
  BUTTON:1,
  SERVICE:2,
  SHAKE:3,
  OPTION:4,
  BUTTONDONE:5
}


export {Priority, TypesOfUsers, DialogStatus,CloseReasonStatus,CandidateState,TypesOfMessages}

