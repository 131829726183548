import {DialogContent, DialogTitle, Link, Modal, ModalClose, ModalDialog, ModalDialogProps, Typography} from "@mui/joy";
import React from "react";
import {identity} from "../../../../types/Alias";
import {UserType} from "../../../../types/UserType";
import {apiUsers} from "../../../../net/rest/ApiUsers";
import {TypesOfUsers} from "../../../../store/Enumerations";
import AvatarWithStatus from "../../../../components/AvatarWithStatus";

type SyncUserSearchType =
    {
        getUserLocator: () => string;
        setTargetUser: (id: identity, name: string) => void;
        getLayout: () => (ModalDialogProps['layout'] | undefined);
        setLayout: (layout: ModalDialogProps['layout'] | undefined) => void;

    }

const SyncUserSearch = (props: SyncUserSearchType) => {

    const {getUserLocator, setTargetUser, getLayout, setLayout} = props;
    const [locatedUsers, setLocatedUsers] = React.useState<UserType[]>([]);

    const localteUsers = async () => {

        const {prep_params, prep_url} = apiUsers.getUsersPrepared(
            [
                ["userdslike", getUserLocator()],
                ["usertypeid", String(TypesOfUsers.Users)], 
                ["pagenumber", "0"], ["pagesize", "999999"]
            ]);

        const response = await fetch(prep_url, prep_params);
        const data = await response.json();
        setLocatedUsers((data.content as unknown) as UserType[]);

    };


    React.useEffect(() => {
        if (!!getLayout()) {

            (async () => {
                await localteUsers();
            })();
        }
        return () => {

        };
    }, [getLayout()]);

    return (
        <React.Fragment>
            <Modal open={!!getLayout()} onClose={() => setLayout(undefined)}
            >
                <ModalDialog layout={getLayout()} sx={{pt: 'var(--Header-height)'}}>
                    <ModalClose sx={{top: 'var(--Header-height)'}} />
                    <DialogTitle>Поиск пользователя c <Typography color="danger" >{getUserLocator()}</Typography> (<code>esc to exit</code> )</DialogTitle>
                    <DialogContent>
                        <div>
                            {(locatedUsers) && locatedUsers.map((value: UserType) => {
                                return (
                                    <>
                                        {(value.userds.toLowerCase().indexOf(getUserLocator().toLocaleLowerCase()) >= 0) && (
                                            <Link onClick={() => {
                                                setTargetUser(String(value.userid), value.userds);
                                                setLayout(undefined)
                                            }}
                                                sx={{p: 0.5}}
                                            >
                                                <AvatarWithStatus usertype={TypesOfUsers.Users} online={true} />
                                                <Typography>{value.userds}</Typography>
                                            </Link>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}

export default SyncUserSearch;