import {observer} from "mobx-react-lite";
import teamStore from "../../../store/TeamStore";
import {AspectRatio, Box,Card, CardContent, CardOverflow, Typography} from "@mui/joy";
import {Android, Person} from "@mui/icons-material";
import usersStore from "../../../store/UsersStore";
import {UserType} from "../../../types/UserType";
import React from "react";
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import {identity, ustring} from "../../../types/Alias";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {apiTeamsUsers} from "../../../net/rest/ApiTeamsUsers";
import {useTranslation} from "react-i18next";
import TeamsEditButtonsPanel from "./components/TeamsEditButtonsPanel";
import {TeamType} from "../../../types/TeamType";
import {TypesOfUsers} from "../../../store/Enumerations";
import KeyIcon from '@mui/icons-material/Key';


const ContentPanel = observer(() => {

  const {t} = useTranslation();
  const [refresh, setRefresh] = React.useState<boolean | undefined>(false);
  //Dialogs state
  const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

  //Dialogs close
  function closeDialog() {
    setDlgDescriptor((reset: EditDialogDescriptor) => {
      return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
    });
  }


  //Dialogs show
  function showDialog() {
    setDlgDescriptor((reset: EditDialogDescriptor) => {
      reset.visible = true;
      return {...reset};
    });
  }

  //Dialogs process result
  function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
    closeDialog();
    // TODO
    if (isapplied) {

      switch (anchor) {
        case "ADD":
          if (!teamStore.SelectedIdteam) {
            currentInfoStore.setInfo(t("TeamERR_NoTeam"), "ERROR");
            break;
          }


          const teamUserTempate = {
            teamid: teamStore.SelectedIdteam,
            userid: Number(result?.get("UserID"))
          }

          apiTeamsUsers.addUserToTeam(

            {
              sucess: (data: TeamType,envoy:any) => {
                teamStore.addTeam(data);
                usersStore.addTeamToUser(Number(envoy.userid),data);
              },
              error: (data: any) => {
                currentInfoStore.setInfo("Teams " + data, "ERROR");
              },
              envoy:{userid:teamUserTempate.userid}

            },
            JSON.stringify(teamUserTempate)
          );
          break;

        case "DEL":

          if (!teamStore.SelectedIdteam) {
            currentInfoStore.setInfo(t("TeamERR_NoTeam"), "ERROR");
            break;
          }
          const teamsMemTemplate = {
            teamid: teamStore.SelectedIdteam,
            userid: Number( usersStore.SelectedIdUser)
          }

          apiTeamsUsers.deleteUserFromTeam(

            {
              sucess: (data: TeamType,envoy:any) => {
                teamStore.addTeam(data);
                usersStore.deleteTeamFromUser(Number(envoy.userid),data);
              },
              error: (data: any) => {
                currentInfoStore.setInfo("Teams " + data, "ERROR");
              },
              envoy:{userid:teamsMemTemplate.userid}
            },
            JSON.stringify(teamsMemTemplate)
          );

          break;
        default:
          break;
      }


    }


  }

  //Adding
  function addRecord() {
    dlgDescriptor.title = t("TeamMember_Add_Title");
    dlgDescriptor.text = t("TeamMember_Add_text");
    dlgDescriptor.apply = t("Button_ADD");
    dlgDescriptor.cancel = t("Button_Cancel");
    dlgDescriptor.elements = [
      {
        format: "SELECT", key: "UserID", title: t("TeamMember_attribute_name"), currentValue: "c4", source: [
          ...usersStore.Display().map<{code: string, value: string}>((item: UserType) => {return {code:  String(item.userid), value: item.userds}})]
      }]
    dlgDescriptor.anchor = "ADD";
    showDialog();
  }

  //Deleting
  function deleteRecord() {
    const selecledUser = usersStore.getUserByID(usersStore.SelectedIdUser);
    if (selecledUser) {
      dlgDescriptor.title = t("TeamMember_Delete_Title");
      dlgDescriptor.cancel = t("Button_Cancel");
      dlgDescriptor.apply = t("Button_Delete");
      dlgDescriptor.text = `${t("Delete_Text")} ${selecledUser.userds} ?`;
      dlgDescriptor.elements = [];
      dlgDescriptor.anchor = "DEL";
      showDialog();
    }
    else {
      currentInfoStore.setInfo(t("TeamERR_NoTeam"), "ERROR");
    }
  }


  React.useEffect(() => {
    if (refresh !== undefined) {
      setRefresh(undefined)
    }
    return () => {

    };
  }, [refresh, teamStore.SelectedIdteam]);

  return (
    <>
      <Box
        sx={{
          height: 'calc(100dvh - var(--Header-height))',
          flexDirection: 'column',
          display: 'flex',
          zIndex: '1000',
        }}>
        <TeamsEditButtonsPanel add={addRecord} delete={deleteRecord} lableText={t("title_user_Teams")} />

        <Box display={"flow"}
          sx={{
            overflow: 'auto',
            width: '100%',
            height: 'calc(90dvh - var(--Header-height))',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill,minmax(250px,5fr))',
            gridRow: 'auto',
            gap: 1,
            gridAutoRows: '100px',
            zIndex: '1001'
          }}>
          {
            usersStore.getUsersByТeamsIDs([teamStore.SelectedIdteam]).map((value: UserType) =>
            (
              <Card orientation="horizontal"
                variant={((usersStore.SelectedIdUser === value.userid)) ? "outlined" : "plain"}
                sx={{
                  height: '100%',
                  backgroundColor: (usersStore.SelectedIdUser === value.userid) ? "#0B6BCB" : (value.usertypeid !== TypesOfUsers.Agents) 
                  ? "primary.softHoverBg" : "#E3FBE3",
                }}
                color="neutral"
                onClick=
                {() => {
                  usersStore.SelectedIdUser = value.userid;
                }}>
                <CardOverflow>
                  <AspectRatio ratio="1" sx={{width: '50px', }}>
                    {(value.usertypeid === TypesOfUsers.Agents) 
                    ?  <Person /> :
                     ((value.usertypeid === TypesOfUsers.Bots)?<Android />:<KeyIcon /> ) 
                    
                    }
                  </AspectRatio>
                </CardOverflow>
                <CardContent sx={{height: '500px'}}>
                  <Typography textColor="common.black" sx={{fontWeight: 'md'}}>
                    {value.userds}
                  </Typography>
                </CardContent>
              </Card>
            ))
          }
        </Box>
        <EditDialog descriptor={dlgDescriptor} />
      </Box>
    </>
  );
});

export {ContentPanel};
