import {RestCallback, genericClient} from "./GenericClient";

class ApiBot {

    api:string=genericClient.setUrl( "/api/bots");

    public getBot(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[],callback,undefined,undefined,queryparams);

    }

    public reloadBot(callback:RestCallback,body:BodyInit)
    {
     
        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }

}
const apiBot = new ApiBot();

export {apiBot};