import {RestCallback, genericClient} from "./GenericClient";

class ApiMessages {

    api:string=genericClient.setUrl( "/api/messages");

    public getMessages(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[],callback,undefined,undefined,queryparams);

    }


}
const apiMessages = new ApiMessages();

export {apiMessages};