import {Environment} from "../../environment";
import ctx, {ContextNames} from "../../store/CurrentContext";
import {CandidateState} from "../../store/Enumerations";
import messagesStore from "../../store/MessagesStore";
import {SocketdataTypeMessage} from "../../types/SocketdataType";
import {messageDispatcher} from "./Dispatcher";
declare const window: any;


function propogateCandidateState(uuid: string,state:number)
{
    messagesStore.propogateCandidat(uuid, /*CandidateState*/ state);
}

function propgateSockState(state: number, stuck: number,queue:number) {
    //ctx.sock(state,stuck,queue)
}

function propgateSockStateQ(queue:number) {
    //ctx.sockQ(queue)
}





export type WrapperSocketMessage = {
    packet: SocketdataTypeMessage;
    uuid: string;
}
const defferQueue: Array<WrapperSocketMessage> = new Array<WrapperSocketMessage>();

let mysock: WebSocket;


function runSocket(
    attempt: number
) {
    propgateSockState(infoState(), attempt, defferQueue.length);
    mysock = new WebSocket(Environment.URL_SOCK+"?"+
        ContextNames.JETTON_HEAD+"="+ctx.token);

    mysock.addEventListener("open", event => {
        propgateSockState(infoState(), attempt, defferQueue.length);
        attempt = 0;
        startPing();
    });

    mysock.addEventListener("message", (event: MessageEvent<string>) => {
        propgateSockState(infoState(), attempt, defferQueue.length);
        messageDispatcher(event.data);

    });

    mysock.addEventListener("close", event => {
        propgateSockState(infoState(), attempt, defferQueue.length);
        stopPing();
        setTimeout(function () {
            if (attempt < Environment.MAX_ATTEMPT) {
                runSocket(attempt + 1);
            }
        }, Environment.RETRY_MS);
    });

    mysock.addEventListener("error", (event) => {
        propgateSockState(infoState(), attempt, defferQueue.length);

    });

}

function isReady() {
    return (readyState() === WebSocket.OPEN)
        && (window.surplusDummyCheck());
}


function readyState(): number {
    return mysock?.readyState || -1;
}

function infoState(): number {
    return window.surplusDummyCheck()?(mysock?.readyState || -1):-1;
}

function sendToSocket(message: WrapperSocketMessage) {
    if (isReady()) {
        try {
            mysock.send(JSON.stringify(message.packet));
            propogateCandidateState(message.uuid, CandidateState.WATING);
        }
        catch (err) {
            propogateCandidateState(message.uuid, CandidateState.ERROR);
        }
    }
    else {
        enqueue(message);
        propogateCandidateState(message.uuid, CandidateState.QUEUING);
        
}


function enqueue(message: WrapperSocketMessage) {
    defferQueue.push(message);
    //propgateSockStateQ(defferQueue.length);
    propgateSockState(infoState(),defferQueue.length, defferQueue.length);
}
}

function dequeue(handler: (message: WrapperSocketMessage) => boolean) {
    if (defferQueue.length > 0) {
        if (handler(defferQueue.at(0) as WrapperSocketMessage)) {
            defferQueue.shift();
            propgateSockStateQ(defferQueue.length);
        }
    };
}

function autoDefferedProcess() {

    if (isReady()) {
        while (defferQueue.length > 0) {
            propgateSockStateQ(defferQueue.length);
            // eslint-disable-next-line no-loop-func
            dequeue((message: WrapperSocketMessage) => {
                if (isReady())
                    try {
                        propogateCandidateState(message.uuid, CandidateState.SENDING)
                        mysock.send(JSON.stringify(message.packet));
                        propogateCandidateState(message.uuid, CandidateState.WATING);
                    }
                    catch (err) {
                        propogateCandidateState(message.uuid, CandidateState.ERROR);
                        return false;
                    }
                return true;
            });
        }
    }
    setTimeout(() => autoDefferedProcess(), Environment.DEFFER_DELAY);
}



let pingTimer: any = undefined;

function startPing() {
    if (Environment.PING > 0)
        pingTimer = setInterval(() => {
            if (mysock.readyState === WebSocket.OPEN) {
                mysock.send(JSON.stringify({type: 'ping'}));
            }
        }, Environment.PING);
}

function stopPing() {
    if (pingTimer !== undefined)
        clearInterval(pingTimer);
}


export {runSocket, sendToSocket, autoDefferedProcess}




