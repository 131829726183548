import {Box, Chip, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Stack, Tooltip, Typography} from "@mui/joy";
import AvatarWithStatus from "../../../../components/AvatarWithStatus";
import {DialogType} from "../../../../types/DialogType";
import dialogsStore from "../../../../store/DialogsStore"
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import DraftsTwoToneIcon from '@mui/icons-material/DraftsTwoTone';
import MailIcon from '@mui/icons-material/Mail';
import {observer} from "mobx-react-lite";
import {identity} from "../../../../types/Alias";
import {DefaultColorPalette} from "@mui/joy/styles/types";
import {useTranslation} from "react-i18next";
import {dateReFormat, MsTodate, Subsctruct, UTCTodate} from "../../../../utils";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PeopleIcon from '@mui/icons-material/People';
import {Priority} from "../../../../store/Enumerations";
import CampaignIcon from '@mui/icons-material/Campaign';

function getColorByPriority(p: number): string {

    switch (p) {
        case Priority.Cricical: {return "danger.500";}
        case Priority.Hight: {return "warning.400";}
        case Priority.Medium: {return "primary.400";}
        case Priority.Low: {return "neutral.500"}
        default: {return "neutral.500"}
    }
}

const MyDialogItem = observer((props: {dialog: DialogType}) => {
    const {t} = useTranslation();
    const {dialog} = props;
    const {news,count} = dialogsStore.hasNews(dialog.dialogid);
    const hewscolor="primary";
 
    //const notAssigned=() && (dialog.assignee.userid) && Number(dialog.assignee.userid) > 0)

    function selectDialog() {
        dialogsStore.select(dialog.dialogid);
        //dialogsStore.changeNewMSG(dialog.dialogid, false);

    }
    return (
        <ListItem
            variant={"plain"}

            sx={{
                backgroundColor: "inherit",
                ml: 0.5,
                mr: 0.5,
            }} >

            <ListItemButton variant="plain"
                onClick={() => selectDialog()}
                sx={{
                    flexDirection: 'column',
                    alignItems: 'initial',
                    gap: 1,
                 
                    pr: 0.5,
                    pb: 1,
                    borderRadius: "md",
                    borderRight: news?2:1,
                    borderLeft: news?6:1,
                    //borderLeftColor: getColorByPriority(dialog.priorityid),
                    //borderRightColor: getColorByPriority(dialog.priorityid),
                    borderLeftColor: news?"primary.400":"primary.200",
                    borderRightColor: news?"primary.400":"primary.200",
                    backgroundColor: (dialogsStore.Selected?.dialogid === dialog.dialogid) ? "primary.softHoverBg" : "inherit"
                }}
            >
                <Stack direction="row" spacing={1.5}
                >
          

                    {news
                        ? (<MailIcon sx={{fontSize: 22}} color={hewscolor}/>)
                        : (<DraftsOutlinedIcon sx={{fontSize: 22}} />)}

                    <Tooltip title={dialog.user.userds} variant="solid">
                        <Box sx={{flex: 1}}>
                            <Typography noWrap
                                {...news ? {level: "title-sm"} : {level: "body-sm"}}
                                {...news ? {color: hewscolor} : {}}
                                sx={{maxWidth: 150}}
                                >
                                {Subsctruct(dialog.user.userds, 21)}
                            </Typography>
                        </Box>
                    </Tooltip>
                    {(news) &&
                                    (<Chip color="primary" variant="solid" size="sm" sx={{fontSize: "12px"}}>{count}</Chip>)} 

                </Stack>

                
                    <Stack direction="row" sx={{pl: 1}}>
                        <Stack direction="column" sx={{pr: 1, width: 150}} >
                   
                            <Chip 
                            {...news ? {color:hewscolor} : {color:"neutral"}} 
                             variant="outlined" size="sm" startDecorator=
                             { (!dialog.assignee?.userid)? (<CampaignIcon color="warning" />):( <SupportAgentIcon />)}
                                sx={{mb: 0.2, p: 0.2}}>
                                {  dialog.assignee?.userds || ""}
                            </Chip>
                        
                            <Chip 
                            {...news ? {color: hewscolor} : {color:"neutral"}}  
                            variant="outlined" size="sm" startDecorator={<PeopleIcon />}>
                                {(dialog.team.teamid) ? dialog.team.teamds : "---"}</Chip>

                        </Stack>
                        <Stack direction="column" >
                            <Tooltip title={dialog.dialogid} variant="solid">
                                <Box
                                    sx={{
                                        lineHeight: 1.5,
                                        textAlign: 'center',

                                    }}
                                >
                                    <Typography level="body-xs"
                                    {...news ? {color: hewscolor} : {}}  >
                                        {MsTodate(Number(dialog.lastupdate))}
                                    </Typography>

                                </Box>
                            </Tooltip>
                            <Box
                                sx={{
                                    lineHeight: 1.5,
                                    textAlign: 'right',

                                }}
                            >
                                <Typography
                                    sx={{color: getColorByPriority(dialog.priorityid)}}
                                    level="body-xs">{t('en_priority_' + dialog.priorityid)}</Typography>

                            </Box>
                        </Stack>
                    </Stack>
               


            </ListItemButton>
        </ListItem>
    );
});
export default MyDialogItem;