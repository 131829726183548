import {observer} from "mobx-react-lite";
import React from "react";
import {Button, Input} from "@mui/joy";

const Layout=observer( ()=>  {

    const [result, setResult] = React.useState<any>();
    const [showInfo, setShow] = React.useState<boolean>(false);
    const [value, setValue] = React.useState("");

    function request() {
        setShow(true);
    }

    function success_process(data: any): void {
        setShow(false);
        setResult(data);

    }
    function error_process(data: any): void {
        setShow(false);
        setResult(data);


    }

    return (
        <>
         <h3>Тест CORS</h3>
         <Button onClick={request}>Запрос</Button>
         <Input
    value={value}
    onChange={(event) => setValue(event.target.value)}
  />
         {(showInfo) && 
         (
            <h1>Запрос ....</h1>
        
         )}
         {
            (!showInfo) && (
            <p> { JSON.stringify( result)}</p>
            )
         }

         
        </>

    );
});


export { Layout};