import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import {Chip, CircularProgress, IconButton, Input, List, ListItem, Modal, ModalClose, Sheet, Stack, Typography} from '@mui/joy';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {sendToSocket, WrapperSocketMessage} from "../../../../net/socket/Sock";
import {InnerTextarea} from './StyledTextArea';
import {MessageType} from '../../../../types/MessageType';
import messageStore from '../../../../store/MessagesStore';
import {useTranslation} from "react-i18next";
import dialogsStore from '../../../../store/DialogsStore';
import currentInfoStore from '../../../../store/CurrentInfoStore';
import {CandidateState, TypesOfMessages} from '../../../../store/Enumerations';
import AttachMsg from '../../../../types/AttachMsgType';
import {Delete, DeleteOutlineSharp, MultipleStop} from '@mui/icons-material';
import {apiDocs} from '../../../../net/rest/ApiDocs';
import ctx from '../../../../store/CurrentContext';
import {DocType} from '../../../../types/DocsType';
import {Reply} from '@mui/icons-material';
import {formatBytes, Subsctruct} from '../../../../utils';
import internal from 'stream';

export default function MsgsBottom(props: {getReplyUUID: () => string | undefined, setReplyUUID: (value: string | undefined) => void}) {


  const {getReplyUUID, setReplyUUID} = props
  const replayMessage = messageStore.get(getReplyUUID())

  const [textAreaValue, setTextAreaValue] = React.useState<string>("");
  const [attachedFile, setAttachedFile] = React.useState<AttachMsg[]>();
  const refBox = React.useRef<HTMLDivElement>(null);
  const refDialog = React.useRef<HTMLInputElement>(null);
  const [prepareMakeLoading, setprepareMakeLoading] = React.useState<boolean>(false);

  const {t} = useTranslation();


  function handleClick() {

    if ((dialogsStore.Selected?.dialogid) &&
      ((textAreaValue !== "") || attachedFile)) {

      if (attachedFile) {
        setprepareMakeLoading(true);
        apiDocs.postDocAttach(
          {
            sucess: (data: DocType, envoy?: any) => {
              setprepareMakeLoading(false);
              if (envoy) {
                sendMessage(
                  envoy.dialogid,
                  envoy.content,
                  envoy.replymessageid,
                  envoy.replycontent,
                  [{...data}]
                )
              }
              setTextAreaValue("");
              setAttachedFile(undefined);
              setReplyUUID(undefined);
            },
            error: (data: any, envoy?: any) => {
              setprepareMakeLoading(false);
              currentInfoStore.setInfo(t("AddDocerror") + data, "ERROR");
            },
            envoy: {
              dialogid: dialogsStore.Selected?.dialogid,
              content: textAreaValue,
              replymessageid: (replayMessage) ? replayMessage.messageid : undefined,
              replycontent: (replayMessage) ? replayMessage.content : undefined
            }
          },
          [...attachedFile as AttachMsg[]],
          [["persist", "0"], ["userid", String(ctx.usid)]]

        )
      } else {
        sendMessage(
          dialogsStore.Selected?.dialogid,
          textAreaValue,
          (replayMessage) ? replayMessage.messageid : undefined,
          (replayMessage) ? replayMessage.content : undefined
        );
        setTextAreaValue("");
        setAttachedFile(undefined);
        setReplyUUID(undefined);
      }
    }

  }

  function sendMessage(dialogid: number, content: string, replymessageid?: number | undefined, replycontent?: string | undefined, attachments?: DocType[] | undefined) {
    const newMessage =
      messageStore.createMessage(
        dialogid,
        TypesOfMessages.TEXT,
        (content === "") ? "Отправлено: " + (attachments ? String(attachments.length) : "0") + " файлов" : content,
        replymessageid,
        attachments,
        undefined,
        replycontent
      );
    const uuid = messageStore.addMessage(newMessage, CandidateState.SENDING);
    sendToSocket({packet: {datatype: "message", data: newMessage}, uuid: uuid} as WrapperSocketMessage);

  }

  //Attach
  const handleClickAttach = () => {
    refDialog.current?.click();
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (e.target.files) {
      if (e.target.files.length !== 0) {
        const ArrAtach: Array<AttachMsg> = [];
        for (let i = 0; i < e.target.files.length; i++) {
          if (e.target.files[i].size > 70 * 1024 * 1024) {
            currentInfoStore.setInfo(t("limitAttachSize"));
          } else {
            ArrAtach.push({docds: e.target.files[i].name, docid: 1, file: e.target.files[i]});
            setAttachedFile(ArrAtach);
          }
        }

      }
    }
  };



  // function shrinkText(str: string, size: number) {
  //   if (str.length > size - 3) {
  //     return str.substring(0, size - 3) + "...";
  //   }
  //   return str
  // }

  return (
    <Box sx={{px: 2, pb: 3}} ref={refBox}>
      <input id="inputLoadFile" type="file" style={{display: 'none'}}
      //onChange={handleFileChange} 
      />
      <FormControl>
        <Textarea
          disabled={prepareMakeLoading}
          slots={{textarea: InnerTextarea}}
          {...replayMessage ? {
            startDecorator: <Stack direction="row">
              <Typography variant='plain' sx={{fontSize: "14px"}} color='primary'
                startDecorator={<Chip color='primary' sx={{fontSize: "14px"}}>{t("Replay")}:</Chip>}>
                {Subsctruct(replayMessage.content, 60)}</Typography>
              <IconButton disabled={prepareMakeLoading} color='primary' variant="plain"
                onClick={() => setReplyUUID(undefined)}>
                <DeleteOutlineSharp sx={{transform: `scale(1.2)`}} />
              </IconButton>
            </Stack>
          } : {}}
          slotProps={{textarea: {placeholder: 'Сообщение', userIs: replayMessage ? "" : ctx.name}}}
          aria-label="Message"
          onChange={(e) => {
            setTextAreaValue(e.target.value);
          }}
          value={textAreaValue}
          minRows={3}
          maxRows={10}

          endDecorator={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
              sx={{
                py: 1,
                pr: 1,
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <div>
                {attachedFile && (
                  <Box
                    sx={{position: 'relative'}}>
                    <Stack direction={'row'} spacing={1} flexWrap={'wrap'}>{attachedFile.map((attachment: AttachMsg) => {
                      return (<>
                        <Typography fontSize="sm" level="body-xs"
                          startDecorator={<Chip color='neutral' sx={{fontSize: "14px"}}>{t("File")}:</Chip>}
                          endDecorator={
                            (!prepareMakeLoading) ? (
                              <IconButton onClick={() => setAttachedFile(undefined)}>
                                <DeleteOutlineIcon sx={{transform: `scale(1.2)`}} />
                              </IconButton>
                            )
                              : (
                                <CircularProgress color='danger' sx={{zoom: "50%"}} />
                              )
                          }
                        >
                          {Subsctruct(attachment.docds, 25)} ({formatBytes(attachment.file?.size)})
                        </Typography>
                      </>)

                    }
                    )}
                    </Stack >
                  </Box>
                )}
              </div>

              <div>
                <input type="file" ref={refDialog} style={{display: 'none'}} multiple
                  //accept='image/*,documents/*,.png,.jpeg,.web'
                  onChange={((e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, "inputLoadFile_"))} />
                <IconButton
                  disabled={prepareMakeLoading}
                  onClick={handleClickAttach}
                ><AttachFileIcon /></IconButton>

                <Button
                  disabled={prepareMakeLoading}
                  size="sm"
                  color="primary"
                  sx={{alignSelf: 'center', borderRadius: 'sm', }}
                  onClick={handleClick}
                >
                  <SendRoundedIcon />
                </Button>
              </div>
            </Stack>
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
              handleClick();
            }
          }}
          sx={{
            '& textarea:first-of-type': {
              minHeight: 72,
              //backgroundColor: 'neutral.solidColor'
            },
          }}
        />
      </FormControl>
    </Box>
  );
}
