import dialogsStore from "../../store/DialogsStore";
import {CandidateState} from "../../store/Enumerations";
import messagesStore from "../../store/MessagesStore";
import {DialogType} from "../../types/DialogType";
import {MessageSType, MessageType} from "../../types/MessageType";
import {SocketdataType} from "../../types/SocketdataType";

export function messageDispatcher(data: any) {

    if (data) {
        const socketdata: SocketdataType = JSON.parse(data) as SocketdataType;

        if (socketdata.datatype === "messages") {
            if (socketdata.data) {
                const messages: MessageSType = socketdata.data as MessageSType;
                if (messages.content) {
                    messages.content.forEach(element => {
                        handlingMessage(element)
                    });
                }
            }
        }
        else

            if (socketdata.datatype === "dialog") {
                if (socketdata.data) {
                    handlingDialog(socketdata.data as DialogType)
                }
            }
            else
                if (socketdata.datatype === "message") {
                    if (socketdata.data) {
                        handlingMessage(socketdata.data as MessageType)
                    }
                }
    }
}


function handlingDialog(dialog: DialogType) {
    dialogsStore.addDialogPreserve(dialog);
}

function handlingMessage(message: MessageType) {

    dialogsStore.addNews(message.dialogid,message.senttime);
    messagesStore.addMessage(message, CandidateState.NO);

}