import { makeAutoObservable } from "mobx";
import { DocTypeService} from "../types/DocsType";
import ctx from '../store/CurrentContext';



/**
 * класс DocsStore отвечает за хранение и предоставление данных по списку файлов
 * и операций над этим список 
 */
class DocsStore {
    private $docs: DocTypeService[] = [];
    private $selected_docid:number = -1;
    private $selected_filenameds:string = "";

    public constructor() {
        makeAutoObservable(this);
    }

     /** возвращает список документов */
     public getDocs(): DocTypeService[] {
        return this.$docs;
    }

    public Display(value: string): DocTypeService[] {
        if (value==="") {
            return this.$docs;
        }
        else {
            return this.$docs.filter(doc => doc.docds.includes(value));
        }
    }

     /** возвращает список документов по списку id
     * используется фильтрация массива там где у элемента id в списке ids переданной в сигнатуре
    */
     public getDocsByIDs(ids_list: number[]): DocTypeService[] {
        return this.$docs.filter((value: DocTypeService) => {
            return ids_list.indexOf(value.docid) >= 0;
        });
    }

     /** возвращает список документов по списку filename
     * используется фильтрация массива 
    */
     public getDocsByNames(names_list: string[]): DocTypeService[] {
        return this.$docs.filter((value: DocTypeService) => {
            return names_list.indexOf(value.docds) >= 0;
        });

    }

     /** возвращает один документ по наименованию filename
     */
     public getDocByName(name: string|any): DocTypeService|undefined {
        const doc=this.getDocsByNames([name]);
        return doc.length===0?undefined:doc[0];
    }

     /** возвращает один документ по ID
     */
     public getDocByID(id: number|any): DocTypeService|undefined {
        const doc=this.getDocsByIDs([Number(id)]);
        return doc.length===0?undefined:doc[0];
    }

     /** чистит список 
    */
     public clearDocs() {
        this.$selected_docid = -1;
        this.$selected_filenameds = "";
        this.$docs = [];

    }

  
    
    

    /** удаляет несколько 
    */
    public deleteDocs(docs: DocTypeService[]) {
        docs.forEach((value: DocTypeService) => {
            let i: number = this.$docs.findIndex((valueF: DocTypeService) => valueF.docid === value.docid);
            if (i >= 0) {
                this.$docs.slice(i, i);
            }
        });
    }

    /** удаляет один
    */
    public deleteDoc(doc: DocTypeService) {
        let i: number = this.$docs.findIndex((valueF: DocTypeService) => valueF.docid === doc.docid);
        if (i >= 0) {
            this.$docs.splice(i, 1);
        }   
    } 

    /** add Doc
    */
    public addDoc(value: DocTypeService) {
        let usid:number|undefined=ctx.usid;
        if(usid===value.userid){
        let i: number = this.$docs.findIndex((valueF: DocTypeService) => valueF.docid === value.docid);
        if (i >= 0) {
            this.$docs[i] = {...value};
        } else {
            this.$docs.push(value);
        }
        }
}


   //ids
   public get SelectedIDDoc()
   { return this.$selected_docid }
   public set SelectedIDDoc(value:number)
   { this.$selected_docid = value }

   //names
   public get SelectedDSFileName()
   { return this.$selected_filenameds}
   public set SelectedDSFileName(value: string)
   { this.$selected_filenameds = value }


}

const docsStore = new DocsStore();
export default docsStore;

