import {observer} from "mobx-react-lite";
import {Box, Button, CircularProgress, Stack, Typography} from "@mui/joy";
import AvatarWithStatus from "../../../../components/AvatarWithStatus";
import messagesStore, {StoredMessageType} from "../../../../store/MessagesStore";
import {MsTodate} from "../../../../utils";
import {KeyboardDoubleArrowUp} from "@mui/icons-material";
import {Dispatch, SetStateAction, useContext, useState} from "react";
import React from "react";
import dialogsStore from "../../../../store/DialogsStore";
import {MessageSType, MessageType} from "../../../../types/MessageType";
import {apiMessages} from "../../../../net/rest/ApiMessages";
import {CandidateState} from "../../../../store/Enumerations";
import ReportIcon from '@mui/icons-material/Report';
import {BubbleMessage} from "./BubbleMessage";
import {Environment} from "../../../../environment";


enum indexDirection {
    back = "back",
    forward = "forward",
    last = "last"
}

const MesgsBody = observer((props: {setReplyUUID: (value: string|undefined) => void}) => {

    const [lightLoading, setLightLoading] = useState<{show: boolean, text?: string}>
        ({show: false, text: "Загрузить ранние сообщения"});
    const [loadingInform, setLoadingInform] = useState<{text: string | undefined, run: boolean}>
        ({text: undefined, run: false});

    const [showUploadButton, setShowUploadButton] = useState<boolean>(false)

    function uploadMessages(dialogid: number | undefined, poolsize: number, indexdir: string, indexid?: number) {

        if (dialogid) {
            const queryparams: [string, string][]
                = [["dialogid", String(dialogid)], ["poolsize", String(poolsize)], ["indexdir", String(indexdir)]];
            if (indexid)
                queryparams.push(["indexid", String(indexid)]);

            apiMessages.getMessages(
                {
                    sucess: (data: MessageSType) => {

                        setShowUploadButton(data.content.length >= poolsize);

                        data.content.forEach(
                            (element => {
                                messagesStore.addMessage(element, CandidateState.NO);
                            })
                        );
                        if (indexdir === indexDirection.last) {
                            setLoadingInform({text: undefined, run: false});
                        }
                        else {
                            setLightLoading({show: false, text: "Загрузить ранние сообщения"});
                        }
                    },
                    error: (err: any) => {
                        if (indexdir === indexDirection.last) {
                            setLoadingInform({text: "Ошибка: " + err, run: false});
                        }
                        else {
                            setLightLoading({show: false, text: "Ошибка: " + err});
                        }
                    }
                },
                queryparams
            );
        }
    }


    function subUploadMessages() {
        if (dialogsStore.Selected) {
            const findMinMessageID = messagesStore.findMinMessageID(dialogsStore.Selected?.dialogid);
            if (findMinMessageID) {
                setLightLoading({show: true, text: "Загрузка ранних сообщений..."});
                uploadMessages(dialogsStore.Selected?.dialogid, Environment.MAXPOOLSIZE, indexDirection.back, findMinMessageID);
            }

        }
    }

    React.useEffect(() => {
        props.setReplyUUID(undefined);
        if (dialogsStore.Selected?.dialogid) {
            setLoadingInform({text: "Загрузка сообщений...", run: true});
            uploadMessages(dialogsStore.Selected?.dialogid, Environment.MAXPOOLSIZE, indexDirection.last);
        }

    }, [dialogsStore.Selected]);

    return (
        <>
            {(loadingInform.text === undefined)
                ? (
                    <>
                        {showUploadButton && (
                            <Box
                                sx={{
                                    // height: "100dvh",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: "center", justifyContent: "center"
                                }}
                            >
                                <Button
                                    variant="plain"
                                    color="neutral"
                                    sx={{fontSize: 12}}
                                    onClick={subUploadMessages}
                                > {(lightLoading.show)
                                    ? (<><CircularProgress sx={{zoom: "50%"}} />
                                        {lightLoading.text}</>)
                                    : (<><KeyboardDoubleArrowUp />
                                        {lightLoading.text}</>)
                                    }
                                </Button>
                            </Box>
                        )}

                        {messagesStore.Display(dialogsStore.Selected?.dialogid).map(
                            (message: StoredMessageType) => {
                                const isme = messagesStore.isMe(message);

                                return (
                                    <Stack
                                        key={message.uuid}
                                        direction="row"
                                        spacing={2}
                                        flexDirection={isme ? 'row-reverse' : 'row'}
                                    //flexDirection={'row'}
                                    >
                                        {!isme && (
                                            <AvatarWithStatus
                                                online={true}
                                                usertype={message.sender.usertypeid}

                                            />
                                        )
                                        }
                                        <BubbleMessage message={message} setReplyUUID={props.setReplyUUID}/>
                                    </Stack>

                                );
                            })}
                    </>)
                : (
                    <Box sx={{
                        height:"250px",
                        width: '100%',
                        alignItems: "center", justifyContent: "center", margin: "auto"
                    }}>
                        <Typography
                            fontWeight="md"
                            fontSize="md"
                            sx={{overflowWrap: "anywhere"}}
                            
                            startDecorator={
                                (loadingInform.run) ? (<CircularProgress size="md" />) : (<ReportIcon />)
                            }
                        >
                            {loadingInform.text}
                        </Typography>
                    </Box>
                )}

        </>

    );
});
export default MesgsBody;