import {Localdata, Targetdata, treehelper} from "./helpers/treehelper"
import {IconButton, Tooltip} from "@mui/joy";
import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useMemo} from 'react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
    ReactFlow,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Edge,
    Node,
    useOnSelectionChange,
    useReactFlow,
    Panel,
    MarkerType,
    getConnectedEdges,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import {StepNode, StepNodeContent} from "./components/StepNode";
import Dagre from '@dagrejs/dagre';
import {useTranslation} from "react-i18next";
import {apiBot} from "../../../net/rest/ApiBot";
import {identity, ustring} from "../../../types/Alias";
import currentInfoStore from "../../../store/CurrentInfoStore";
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import StepCustomEdge from "./components/StepCustomEdge";
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import usersStore from "../../../store/UsersStore";
import {UserType} from "../../../types/UserType";
import teamStore from "../../../store/TeamStore";
import {TeamType} from "../../../types/TeamType";
import docsStore from "../../../store/DocsStore";

import MsgBox from "../../../components/MsgBox";
import {castJsonToStringOrDes} from "../../../utils";
import AddHomeIcon from '@mui/icons-material/AddHome';
import {TypesOfUsers} from "../../../store/Enumerations";
import {DocType} from "../../../types/DocsType";





const getLayoutedElements = (nodes: Node[], edges: Edge[], options: any) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({rankdir: options.direction});

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) =>
        g.setNode(node.id, {
            ...node,
            width: node.measured?.width ?? 0,
            height: node.measured?.height ?? 0,
        }),
    );
    Dagre.layout(g);
    return {
        nodes: nodes.map((node) => {
            const position = g.node(node.id);
            const x = position.x - (node.measured?.width ?? 0) / 2;
            const y = position.y - (node.measured?.height ?? 0) / 2;
            return {...node, position: {x, y}};
        }),
        edges,
    };
};

const Flow = observer(() => {

    const {t} = useTranslation();

    const defaultViewport = {x: 0, y: 0, zoom: 0.5};
    const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
    const [updateNodeContent, setUpdateNodeContent] = React.useState<StepNodeContent | undefined>(undefined);
    const nodeTypes = useMemo(() => ({stepNode: StepNode}), []);
    const [incrementKey, setIncrementKey] = React.useState<number>(1);


    const edgeTypes = {
        stepEdge: StepCustomEdge,
    };

    useEffect(() => {
        setNodes((nds: Node[]) =>
            nds.map((node) => {
                if ((updateNodeContent) && (node.id === updateNodeContent.id)) {
                    return {
                        ...node,
                        data: {
                            ...updateNodeContent
                        },
                    };
                }

                return node;
            }),
        );
    }, [updateNodeContent, setNodes]);



    const addNode = useCallback((datanode: StepNodeContent) => {
        setNodes((nodes: Node[]) => {
            const upNode = nodes.filter((value: Node) => {return value.id === datanode.normalized});

            const x = (upNode.length > 0) ?
                (upNode[0].position.x + ((upNode[0].measured?.width) ? (upNode[0].measured?.width / 2) : 50))
                : 100;
            const y = (upNode.length > 0) ? (upNode[0].position.y + ((upNode[0].measured?.height) ? upNode[0].measured?.height : 200) + 20) : 100;
            return [
                ...nodes,
                {
                    id: datanode.id, position: {x: x, y: y}, data:
                        datanode, type: "stepNode"
                }
            ];
        });
        if (datanode.normalized !== "0") {
            setEdges((eds) =>
                addEdge({
                    id: datanode.normalized + "-" + datanode.id,
                    source: datanode.normalized,
                    target: datanode.id,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 30,
                        height: 30,
                    },
                    type: "stepEdge"
                    , data: {setEdges},
                }, eds)
            );
        }
    }, [setNodes, setEdges]);

    // msg box
    const [msgBoxContent, setMsgBoxContent] = React.useState<{caption: string, text: string, open: boolean}>({caption: "", text: "", open: false});

    function mutaing(re?: {caption: string, text: string, open: boolean}): {caption: string, text: string, open: boolean} {
        if (re !== undefined) {
            setMsgBoxContent(re);
            return re;
        }
        else {
            return msgBoxContent;
        }
    }


    //layout 

    const {fitView} = useReactFlow();

    const onLayout = useCallback(
        (direction: string) => {

            const layouted = getLayoutedElements(nodes, edges, {direction});

            setNodes([...layouted.nodes]);
            setEdges([...layouted.edges]);

            window.requestAnimationFrame(() => {
                fitView();
            });
        },
        [nodes, edges],
    );

    // rest call

    const uploadRequestNodes = useCallback((botID: string) => {
        setNodes([]);
        setEdges([]);
        apiBot.getBot (
            {sucess: successRequestNodes, error: errorRequestNodes},
            [["botid", String(botID)]]
        );
    }, [setNodes, setEdges]
    );

    function successRequestNodes(data: any): void {
        if (!data.steps) {
            currentInfoStore.setInfo("Relect: " + castJsonToStringOrDes(data), "ERROR");
        }
        const treedata: Map<string, Localdata> = treehelper.intialte(data, 200, 200);
        if (treedata.size < 1)
            return;
        setIncrementKey((keyIncrementValue) => {
            treedata.forEach((value: Localdata) => {
                setNodes((nds: Node[]) =>
                    [...nds,
                    {
                        id: value.id,
                        position:
                        {
                            x: (value.content) ? value.content.x : 0,
                            y: (value.content) ? value.content.y : 0

                        }
                        , data:
                        {
                            id: value.id,
                            normalized: value.normalized,
                            typeid: value.typeid,
                            text: value.text,
                            botid: value.botid,
                            bottxt: (value.botid) ? usersStore.getUserByID(Number(value.botid))?.userds : undefined,
                            docid: value.docid,
                            doctxt: (value.docid) ? docsStore.getDocByID(value.docid)?.docds : undefined,
                            teamid: value.teamid,
                            teamtxt: (value.teamid) ? teamStore.getTeamByID(Number(value.teamid))?.teamds : undefined,
                            editcallback: editNode,
                            newcallback: newNode,
                        }, type: "stepNode"
                    }
                    ]
                );
                if (value.normalized !== "0") {
                    setEdges((eds) =>
                        addEdge({
                            id: value.normalized + "-" + value.id,
                            source: String(value.normalized),
                            target: value.id,
                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                                width: 30,
                                height: 30,
                            },
                            type: "stepEdge"
                            , data: {setEdges},
                        }, eds)
                    );

                }
            });
            return keyIncrementValue + treehelper.intiIncrement(treedata);
        });
    }

    function errorRequestNodes(data: any): void {
        currentInfoStore.setInfo("REST REQUEST: " + data, "ERROR");
        // setShowStatusLeft(false);
    }


    React.useEffect(() => {
        if (usersStore.SelectedIdUser !== -1)
            uploadRequestNodes(String(usersStore.SelectedIdUser));
        return () => {
        };
    }, [usersStore.SelectedIdUser]);


    // new line
    const onConnect = useCallback(
        (params: any) => {
            setEdges((eds) =>
                addEdge({
                    id: params.source + "-" + params.target,
                    source: params.source,
                    target: params.target,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 30,
                        height: 30,
                    },
                    type: "stepEdge"
                    , data: {setEdges},
                }, eds)
            );
            setNodes((nds: Node[]) =>
                nds.map((node) => {
                    if ((node.id === params.target)) {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                normalized: params.source
                            },
                        };
                    }

                    return node;
                }),
            );

        },
        [setEdges, setNodes]
    );



    // select
    const [selectedNodes, setSelectedNodes] = React.useState<string[]>([]);
    const [selectedEdges, setSelectedEdges] = React.useState<string[]>([]);
    const onChange = useCallback(
        (params: {nodes: Node[], edges: Edge[]}

        ) => {
            setSelectedNodes(params.nodes.map((node: Node) => node.id));
            setSelectedEdges(params.edges.map((edge: Edge) => edge.id));
        }, []);

    useOnSelectionChange({
        onChange,
    });
    // delete by event
    const onNodesDelete = useCallback(
        (deleted: Node[]) => {
            setEdges(
                deleted.reduce(
                    (acc, node) => {
                        /*  for future  
                        const incomers = getIncomers(node, nodes, edges);
                        const outgoers = getOutgoers(node, nodes, edges);
                        */
                        const connectedEdges = getConnectedEdges([node], edges);

                        const remainingEdges = acc.filter(
                            (edge) => !connectedEdges.includes(edge),
                        );

                        const createdEdges: Edge[] = []; /*  for future  incomers.flatMap(({id: source}) =>
                        outgoers.map(({id: target}) => ({
                            id: `${source}->${target}`,
                            source,
                            target,
                        })),
                    );
                    */
                        return [...remainingEdges, ...createdEdges];
                    }, edges),
            );

        },
        [nodes, edges, setEdges],
    );

    const onEdgesDelete = useCallback(
        (edges: Edge[]) => {
            console.log("delete");
        }
        , []);

    //Dialogs state
    const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

    //Dialogs close
    function closeDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
        });
    }

    //Dialogs show
    function showDialog(dlg: EditDialogDescriptor) {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            dlg.visible = true;
            return {...dlg};
        });
    }

    //Dialogs process result
    function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>, linkedresult?: Map<string, Object>, $?: any) {
        closeDialog();
        // TODO
        if (isapplied) {

            switch (anchor) {
                case "EDIT":
                    if ($) {
                        if (result?.has("text")) {
                            $.text = String(result.get("text"));
                        }
                        if ($.typeid === "0") {
                            if (result?.has("docid")) {
                                $.docid = result.get("docid");
                                if ($.docid === "") $.docid = undefined;
                                $.doctxt = undefined;
                                if ($.docid) {
                                    $.doctxt = docsStore.getDocByID($.docid)?.docds
                                }
                            }
                        } else {
                            $.botid = undefined;
                            $.bottxt = undefined;
                            $.teamid = undefined;
                            $.teamtxt = undefined;

                            if ((result?.has("bindoption")) &&
                                (result?.get("bindoption") !== "")) {

                                if (result?.has("botid") && (result?.get("bindoption") === "bot")) {
                                    $.botid = result.get("botid");
                                    if ($.botid === "") $.botid = undefined;
                                    $.bottxt = undefined;
                                    if ($.botid) {
                                        $.bottxt = usersStore.getUserByID(Number($.botid))?.userds
                                    }
                                }
                                if (result?.has("teamid") && (result?.get("bindoption") === "team")) {
                                    $.teamid = result.get("teamid");
                                    if ($.teamid === "") $.teamid = undefined;
                                    $.teamtxt = undefined;
                                    if ($.teamid) {
                                        $.teamtxt = teamStore.getTeamByID($.teamid)?.teamds
                                    }
                                }
                            }

                        }

                        setUpdateNodeContent($);
                    }

                    break;
                case "NEW":
                    if ($) {

                        if (result?.has("text")) {
                            $.text = String(result.get("text"));
                        }
                        if ($.typeid === "0") {
                            if (result?.has("docid")) {
                                $.docid = result.get("docid");
                                if ($.docid === "") $.docid = undefined;
                                $.doctxt = undefined;
                                if ($.docid) {
                                    $.doctxt = docsStore.getDocByID($.docid)?.docds
                                }
                            }
                        } else {

                            $.botid = undefined;
                            $.bottxt = undefined;
                            $.teamid = undefined;
                            $.teamtxt = undefined;

                            if ((result?.has("bindoption")) &&
                                (result?.get("bindoption") !== "")) {

                                if (result?.has("botid") && (result?.get("bindoption") === "bot")) {
                                    $.botid = result.get("botid");
                                    if ($.botid === "") $.botid = undefined;
                                    $.bottxt = undefined;
                                    if ($.botid) {
                                        $.bottxt = usersStore.getUserByID(Number($.botid))?.userds
                                    }
                                }
                                if (result?.has("teamid") && (result?.get("bindoption") === "team")) {
                                    $.teamid = result.get("teamid");
                                    if ($.teamid === "") $.teamid = undefined;
                                    $.teamtxt = undefined;
                                    if ($.teamid) {
                                        $.teamtxt = teamStore.getTeamByID($.teamid)?.teamds
                                    }
                                }
                            }
                        }
                        addNode($);
                    }

                    break;

                case "COPY":
                    if (result?.has("botid")) {
                        const v = result?.get("botid");
                        uploadRequestNodes(v ? v : "0");
                    }
                    break;
                default:
                    break;
            }


        }

    }

    function editNode(data: StepNodeContent) {
        dlgDescriptor.title = t("Ncapt");
        dlgDescriptor.orientation = "vertical";
        dlgDescriptor.$ = data;
        dlgDescriptor.elements = [

            {format: "TEXTAREA", key: "text", title: t("ntext"), currentValue: data.text, sxLable: {minWidth: 100}, sxInput: {minWidth: 400, }},
        ]
        if (data.typeid !== "0") {

            dlgDescriptor.elements.push(
                {
                    format: "ECLOSED_OPTSEL", key: "bindoption", title: "Выбор перенаправления",
                    currentValue: ((data.botid === undefined) && (data.teamid === undefined)) ? "" :
                        ((data.botid !== undefined) ? "bot" : ((data.teamid !== undefined) ? "team" : "")),
                    sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                    source: [
                        {code: "", value: t("nopt")},
                        {
                            code: "bot", value: t("nbotid"),
                            enclosed: {
                                format: "SELECT", key: "botid", title: "", currentValue: data.botid === undefined ? "" : data.botid,
                                sxInput: {minWidth: 205},
                                source: [
                                    {code: "", value: ""},
                                    ...usersStore.Display((value) => value.usertypeid === TypesOfUsers.Bots).map((user: UserType) => {
                                        return {code: String(user.userid), value: user.userds}
                                    }
                                    )],
                                bindindex: "bindoption",
                                bindvalue: "bot"

                            }
                        },
                        {
                            code: "team", value: t("nteamid"),
                            enclosed: {
                                format: "SELECT", key: "teamid", title: "", currentValue: data.teamid === undefined ? "" : data.teamid,
                                sxInput: {minWidth: 205},
                                source: [
                                    {code: "", value: ""},
                                    ...teamStore.getTeams().map((team: TeamType) => {
                                        return {code: String(team.teamid), value: team.teamds}
                                    }
                                    )],
                                bindindex: "bindoption",
                                bindvalue: "team"

                            }
                        },
                    ]

                });

        }
        else {
            dlgDescriptor.elements.push(
                {
                    format: "SELECT", key: "docid", title: t("ndocid"), currentValue: data.docid === undefined ? "" : data.docid,
                    sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                    source: [
                        {code: "", value: ""},
                        ...docsStore.getDocs().map((doc: DocType) => {
                            return {code: String(doc.docid), value: doc.docds}
                        }
                        )]
                });

        }
        dlgDescriptor.anchor = "EDIT";
        showDialog(dlgDescriptor);
    }

    function newNode(data: StepNodeContent | undefined, typeid: string) {
        dlgDescriptor.title = (typeid === "0") ? t("Ncaptnc") : t("Ncaptn");
        dlgDescriptor.orientation = "vertical";

        setIncrementKey((incrementKeyValue) => {
            dlgDescriptor.$ = {
                id: String(incrementKeyValue + 1),
                normalized: (data === undefined ? "0" : data.id),
                typeid: typeid,
                text: "",
                botid: undefined,
                bottxt: undefined,
                docid: undefined,
                doctxt: undefined,
                teamid: undefined,
                teamtxt: undefined,
                editcallback: editNode,
                newcallback: newNode
            };
            dlgDescriptor.elements = [
                {format: "TEXTAREA", key: "text", title: t("ntext"), currentValue: "", sxLable: {minWidth: 100}, sxInput: {minWidth: 400, }},
            ]
            if (typeid !== "0") {

                dlgDescriptor.elements.push(
                    {
                        format: "ECLOSED_OPTSEL", key: "bindoption", title: "Выбор перенаправления",
                        currentValue: "",
                        sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                        source: [
                            {code: "", value: t("nopt")},
                            {
                                code: "bot", value: t("nbotid"),
                                enclosed: {
                                    format: "SELECT", key: "botid", title: "", currentValue: "",
                                    sxInput: {minWidth: 205},
                                    source: [
                                        {code: "", value: ""},
                                        ...usersStore.Display((value) => value.usertypeid === TypesOfUsers.Bots).map((user: UserType) => {
                                            return {code: String(user.userid), value: user.userds}
                                        }
                                        )],
                                    bindindex: "bindoption",
                                    bindvalue: "bot"

                                }
                            },
                            {
                                code: "team", value: t("nteamid"),
                                enclosed: {
                                    format: "SELECT", key: "teamid", title: "", currentValue: "",
                                    sxInput: {minWidth: 205},
                                    source: [
                                        {code: "", value: ""},
                                        ...teamStore.getTeams().map((team: TeamType) => {
                                            return {code: String(team.teamid), value: team.teamds}
                                        }
                                        )],
                                    bindindex: "bindoption",
                                    bindvalue: "team"

                                }
                            },
                        ]

                    });

            }
            else {
                dlgDescriptor.elements.push(
                    {
                        format: "SELECT", key: "docid", title: t("ndocid"), currentValue: "",
                        sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                        source: [
                            {code: "", value: ""},
                            ...docsStore.getDocs().map((doc: DocType) => {
                                return {code: String(doc.docid), value: doc.docds}
                            }
                            )]
                    });

            }
            dlgDescriptor.anchor = "NEW";
            showDialog(dlgDescriptor);
            return incrementKeyValue + 1
        });
    }


    // copy from

    function copyFrom() {
        dlgDescriptor.title = t("Ncopy");
        dlgDescriptor.orientation = "vertical";

        dlgDescriptor.elements = [
            {

                format: "SELECT", key: "botid", title: "", currentValue: "",
                sxInput: {minWidth: 205},
                source: [
                    {code: "", value: ""},
                    ...usersStore.Display((value) => value.usertypeid === TypesOfUsers.Bots).map((user: UserType) => {
                        return {code: String(user.userid), value: user.userds}
                    }
                    )]
            }

        ]

        dlgDescriptor.anchor = "COPY";
        showDialog(dlgDescriptor);

    }



    // save and upload
    const onSaveCanvas = useCallback(
        () => {
            const localData: Map<string, Localdata> = new Map();
            const targetData: Targetdata[] = [];
            try {
                nodes.forEach((node: Node, _, array: Node[]) => {
                    const filtered: Edge[] = edges.filter((edge: Edge) => {
                        return edge.target === node.id;
                    }
                    );
                    if (filtered.length > 1) {
                        mutaing({caption: "Ошибка узел " + node.id, text: "Два родительских элемента", open: true})
                        throw new Error();
                    }
                    if (filtered.length > 0) {
                        const parentIndex = array.findIndex((elem: Node) => {
                            return elem.id === filtered[0].source;
                        })
                        if (parentIndex > -1) {
                            const parent: Node = array[parentIndex];
                            if ((node.data.typeid === "0") && (parent.data.typeid === node.data.typeid)) {
                                mutaing({caption: "Ошибка узел " + node.id, text: "Узел 'Вопрос' не может быть дочерним узлом другого вопроса", open: true})
                                throw new Error();
                            }
                        }
                    }
                    if (filtered.length === 0) {
                        if (node.data.normalized !== "0") {
                            mutaing({caption: "Ошибка узел " + node.id, text: "Узел должен состоять в иерархии", open: true})
                            throw new Error();
                        }
                    }
                    localData.set(String(node.data.id),
                        {
                            id: String(node.data.id),
                            partid: "",
                            typeid: String(node.data.typeid),
                            text: String(node.data.text),
                            botid: (node.data.botid !== undefined) ? String(node.data.botid) : undefined,
                            docid: (node.data.docid !== undefined) ? String(node.data.docid) : undefined,
                            teamid: (node.data.teamid !== undefined) ? String(node.data.teamid) : undefined,
                            content: {x: Math.round(node.position.x), y: Math.round(node.position.y)},
                            normalized: (filtered.length === 0) ? "0" : String(filtered[0].source)
                        }
                    );
                }

                );
                let basedcount = 0;
                localData.forEach((value, _, array) => {
                    if (value.typeid !== "0") {
                        const parentNode = array.get(String(value.normalized));
                        if ((parentNode !== undefined) && (parentNode.typeid === "0")) {
                            value.partid = String(parentNode.normalized);
                        }
                        else {
                            value.partid = String(value.normalized);
                        }
                    }
                    else {
                        value.partid = String(value.normalized);
                        if (value.normalized === "0") {
                            basedcount = basedcount + 1;
                            if (basedcount > 1) {
                                mutaing({caption: "Ошибка базовых узлов", text: "Найдено больше чем один базовый узел", open: true})
                                throw new Error();
                            }
                        }
                    }

                    targetData.push({
                        botstepid: Number(value.id),
                        parentstepid: Number(value.partid),
                        messagetypeid: Number(value.typeid),
                        textds: String(value.text),
                        botid: (value.botid !== undefined) ? Number(value.botid) : undefined,
                        docid: (value.docid !== undefined) ? Number(value.docid) : undefined,
                        teamid: (value.teamid !== undefined) ? Number(value.teamid) : undefined,
                        //content: (value.content !== undefined) ? JSON.stringify(value.content) : undefined,
                        content: (value.content !== undefined) ? value.content : undefined,
                    }
                    );
                }
                );
                if (basedcount === 0) {
                    mutaing({caption: "Ошибка базовых узлов", text: "Обязательно должен быть один базовый узел", open: true})
                    throw new Error();
                }

                const postObject = {
                    botid: usersStore.SelectedIdUser,
                    steps: targetData
                }   


                apiBot.reloadBot(
                    {
                        sucess: (data: any, envoy?: any) => {
                            //currentInfoStore.setInfo("bot " + data, "INFO");

                        },
                        error: (data: any, envoy?: any) => {
                            currentInfoStore.setInfo("bot " + data, "ERROR");
                        }
                    },
                    JSON.stringify(postObject)
                );
            }
            catch (breakCycles) {
                //
            }

        }
        , [nodes]
    );




    return (
        <>

            <ReactFlow
                nodes={nodes}
                edges={edges}
                defaultViewport={defaultViewport}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                onConnect={onConnect}
                onNodesDelete={onNodesDelete}
                edgeTypes={edgeTypes}
                onEdgesDelete={onEdgesDelete}
            >
                <Controls />
                <MiniMap pannable zoomable />
                <Background gap={12} size={1} color="lightgray " />
                <Panel position="top-left">



                    <Tooltip title={t("nodes_save")}>
                        <IconButton onClick={onSaveCanvas} color="primary" variant="soft" size="sm" sx={{mr: 1.5}}><FileUploadRoundedIcon></FileUploadRoundedIcon></IconButton>
                    </Tooltip>
                    <Tooltip title={t("root_add")}>
                        <IconButton onClick={() => {newNode(undefined, "0")}} color="primary" variant="soft" size="sm" sx={{mr: 0.5}}><AddHomeIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title={"Clear all"}>
                        <IconButton onClick={() => {
                            setNodes([]);
                            setEdges([]);
                        }} color="primary" variant="soft" size="sm" sx={{mr: 0.5}}><RemoveRoadIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title={"Copy from"}>
                        <IconButton onClick={copyFrom} color="primary" variant="soft" size="sm" sx={{mr: 0.5}}><AutoFixHighIcon /></IconButton>
                    </Tooltip>

                </Panel>
                <Panel position="top-right">
                    <Tooltip title={t("vertical_layout")}>
                        <IconButton onClick={() => onLayout('TB')} color="neutral" variant="soft" size="sm" sx={{mr: 0.5}}><AlignHorizontalLeftIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title={t("horizontal_layout")}>
                        <IconButton onClick={() => onLayout('LR')} color="neutral" variant="soft" size="sm" sx={{mr: 0.5}}><AlignVerticalBottomIcon /></IconButton>
                    </Tooltip>
                </Panel>

            </ReactFlow>
            <EditDialog descriptor={dlgDescriptor} />
            <MsgBox mutaing={mutaing} />

        </>

    );

}


);

export default Flow