
function isStringTestJson(a: any) {
    try {
        JSON.parse(a);
    } catch (e) {
        return false;
    }
    return true;
}

function castJsonToStringOrDes(a: any): string {
    if (isStringTestJson(a)) {
        return JSON.stringify(a);
    }
    else {
        let str: string = "";
        for (const key in a) {
            str = str + key + "=" + a[key];
        }
        return str;
    }
}

function dateReFormat(value: string, inf: string, outf: string): string {
    let y = inf.indexOf("yyyy"), ysize = 4;
    if (y === -1) {
        y = inf.indexOf("yy");
        ysize = 2;
    }
    let m = inf.indexOf("mm");
    let d = inf.indexOf("dd");
    let h = inf.indexOf("HH");
    let mi= inf.indexOf("MM");
    let s= inf.indexOf("SS")
    if (y > -1) {
        outf=outf.replace(((ysize > 2) ? "yyyy" : "yy"),value.substring(y, y+ysize));
    }
    if (m > -1) {
        outf=outf.replace("mm",value.substring(m, m+2));
    }
    if (d > -1) {
        outf=outf.replace("dd",value.substring(d, d+2));
    }
    if (h > -1) {
        outf=outf.replace("HH",value.substring(h, h+2));
    }
    if (mi > -1) {
        outf=outf.replace("MM",value.substring(mi, mi+2));
    }
    if (s > -1) {
        outf=outf.replace( "SS",value.substring(s, s+2));
    }
    return outf;
}

//Convert date to epoch or unix timestamp in TypeScript
function dateToMs_String(data: Date): string {
    let date_String: string = (data.getTime()/1000).toFixed().toString();
    return date_String;
  }

  //Convert epoch or Unix timestamp to date in TypeScript
function MsTodate(data: number): String {
    let date_String: Date = new Date(data*1000);
    return date_String.toLocaleDateString("ru-RU")+" "+date_String.toLocaleTimeString("ru-RU");
  }

  function DateToEpoch(date: Date):number
  {
    return  Math.floor(date.getTime() / 1000);
  }
  
  function UTCTodate(date: number,nodif:boolean=true): string {
    function plusZero(v: number) {
        return ((v < 10) ? "0" : "") + String(v);
    }
    var utc = new Date(date * 1000);
    var offset = utc.getTimezoneOffset();
    var local = new Date(utc.getTime() + ((!nodif) ?offset * 60000:0));
    return plusZero(local.getDate()) + "."
        + plusZero(local.getMonth())
        //  + local.getFullYear() 
        + " " + plusZero(local.getHours()) + ":" +
        +plusZero(local.getMinutes());


}


function Subsctruct(value: string|undefined, count: number) {
    if (value === undefined) value = "";
    if (value.length > count) {
        value = value.substring(0, count - 1) + "...";
    }
    return value;
}



export function openSidebar() {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
  }
  
  export function closeSidebar() {
    if (typeof window !== 'undefined') {
      document.documentElement.style.removeProperty('--SideNavigation-slideIn');
      document.body.style.removeProperty('overflow');
    }
  }
  
  export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const slideIn = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--SideNavigation-slideIn');
      if (slideIn) {
        closeSidebar();
      } else {
        openSidebar();
      }
    }
  }

  export function openMessagesPane() {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.setProperty('--MessagesPaneCateg-slideIn', '1');
    }
  }
  
  export function closeMessagesPane() {
    if (typeof window !== 'undefined') {
      //document.documentElement.style.removeProperty('--MessagesPaneCateg-slideIn');
      document.documentElement.style.setProperty('--MessagesPaneCateg-slideIn', '0');
      document.body.style.removeProperty('overflow');
    }
  }
  
  export function toggleMessagesPane() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const slideIn = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--MessagesPaneCateg-slideIn');
      if (slideIn==="1") {
        closeMessagesPane();
      } else {
        openMessagesPane();
      }
    }
  }


  function formatBytes(size: number | undefined, decimals = 2) {
    if (size) {
      if (!+size) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(size) / Math.log(k))
      return `${parseFloat((size / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`

    } else {
      return "1";
    }
  }


export {isStringTestJson, castJsonToStringOrDes,dateReFormat, dateToMs_String,formatBytes, MsTodate,UTCTodate,Subsctruct,DateToEpoch}

