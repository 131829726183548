import {Sheet} from "@mui/joy";
import {observer} from "mobx-react";

import {ContentPanel} from "./ContentPanel";


const Layout = observer(() => {

  return (
    <>
      <Sheet>
        
    
          <ContentPanel />
      

      </Sheet>
    </>

  );
});

export {Layout};
