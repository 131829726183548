import {RestCallback, genericClient} from "./GenericClient";

class ApiUsers {

    api:string=genericClient.setUrl( "/api/users");

    public getUsers(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[['Content-Type', 'application/json']],callback,undefined,undefined,queryparams);
    }

    public getOneUserPrepared(usid:string)
    {
        return genericClient.prepareCallHttp(this.api,"GET",[['Content-Type', 'application/json']],undefined,undefined, [["userid", usid], ["pagesize", "1"], ["pagenumber", "0"]]);
    }
    
    public getUsersPrepared(queryparams?:[string, string][])
    {
       return genericClient.prepareCallHttp(this.api,"GET",[['Content-Type', 'application/json']],undefined,undefined,queryparams);
    }

    public createUser(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public updateUser(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }
    public deleteUser(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }
   
}
const apiUsers = new ApiUsers();

export {apiUsers};