import {makeAutoObservable} from "mobx";
import {DialogSType, DialogType} from "../types/DialogType";
import {UserType} from "../types/UserType";
import {DialogStatus} from "./Enumerations";
import {UTCTodate} from "../utils";


function isnull(value: any | undefined) {
    if (value === undefined) return 0;
    return Number(value)
}

export type PreSetSection =
    {
        section: "ITEM" | "KEY"
        , item: "ALL" | "MY" | "ARH" | string
    };

class DialogsStore {

    // Tail
    private $dialogPreSetSection: PreSetSection = {section: "ITEM", item: "MY"};
    public tailPreSetSection: string = this.$dialogPreSetSection.section + this.$dialogPreSetSection.item;

    public set DialogPreSetSection(preset: PreSetSection) {

        this.$dialogPreSetSection.section = preset.section;
        this.$dialogPreSetSection.item = preset.item;
        this.tailPreSetSection = this.$dialogPreSetSection.section + this.$dialogPreSetSection.item;
    }

    public get DialogPreSetSection(): PreSetSection {

        return this.$dialogPreSetSection;
    }


    // Watcher
    private $watcherNews: Map<number, number> = new Map();

    public addNews(dialogid: number, senttime: number) {

        if (this.$selected?.dialogid !== dialogid) {
            this.$watcherNews.set(dialogid, 1 + isnull(this.$watcherNews.get(dialogid)))
        }
    }

    public clearNews(dialogid: number) {
        this.$watcherNews.delete(dialogid);
    }

    public hasNews(dialogid: number): {news: boolean, count: number} {
        let count = isnull(this.$watcherNews.get(dialogid));
        return {news: count > 0, count: count}
    }


    public hasAny(): {news: boolean, count: number} {
        let count = 0;
        this.$watcherNews.forEach((value: number, key: number) => count = count + value);
        return {news: count > 0, count: count}
    }

    public hasUserNews(user: UserType | undefined): {news: boolean, count: number} {
        let count = 0;
        if (!user) return {news: false, count: 0};

        this.$watcherNews.forEach((value: number, key: number) => {
            if (this.$dialogs.get(key)?.dialog.assignee?.userid === user.userid) {
                count = count + value;
            }
        });

        return {news: count > 0, count: count}
    }

    public hasTeamNews(teaid: number): {news: boolean, count: number} {
        let count = 0;
        this.$watcherNews.forEach((value: number, key: number) => {
            if (this.$dialogs.get(key)?.dialog.team.teamid === teaid) {
                count = count + value;
            }
        });

        return {news: count > 0, count: count}
    }



    // Base
    private $dialogs: Map<number, {dialog: DialogType, breed: string | undefined}> = new Map();
    private $selected: DialogType | undefined = undefined;
    private $breed: string = this.newBreed();

    public get breed()
    {
        return this.$breed
    }


    public constructor() {
        makeAutoObservable(this);
    }


    public clear() {
        this.$selected = undefined
        this.$dialogs.clear();
    }

    public reset() {
        this.$selected = undefined
    }

    /** add Dialog Breed
    */

    public newBreed() {
        return "b" + String((new Date()).getTime())
    }


    public addDialogBatchBreed(batch: DialogSType) {
        this.reset();
        this.$breed = this.newBreed();
        batch.content.forEach((value: DialogType) => {
            this.addDialogBreed(value, this.$breed);
        });
    }

    public addDialogBreed(value: DialogType, breed: string) {
        this.$dialogs.set(value.dialogid, {dialog: value, breed: breed});
    }

    public addDialogPreserve(value: DialogType) {

        this.$dialogs.set(value.dialogid, {dialog: value, breed: this.$dialogs.get(value.dialogid)?.breed});

    }



    public get Selected(): DialogType | undefined {
        return this.$selected;
    }


    public Display(predicate?: (value: DialogType) => boolean): DialogType[] {

        const result: DialogType[] = [];
        Array.from(this.$dialogs.values()).forEach(value => {
            if (this.$breed === value.breed) {
                if (predicate) {
                    if (predicate(value.dialog)) {
                        result.push(value.dialog)
                    }
                }
                else
                    result.push(value.dialog)
            }
        });
        return result;

    }

    public select(id: number): void {
        this.$selected = this.$dialogs.get(id)?.dialog;
        if (this.$selected) this.clearNews(this.$selected.dialogid);
    }
    public eligibleForUser(user: UserType | undefined, dialog: DialogType | undefined) {
        if ((user) && (dialog) && (dialog.statusid !== DialogStatus.Close)) {
            if (user.userid === dialog.assignee?.userid) return true;
            if (user.userid === dialog.user.userid) return true;
            return user.teams.findIndex((team) => team.teamid === dialog.team.teamid) > -1;
        }
        else return false;
    }


}

const dialogsStore = new DialogsStore();
export default dialogsStore;