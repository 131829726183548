import {observer} from "mobx-react-lite";
import docsStore from "../../../store/DocsStore";
import {Avatar, Box, Chip, CircularProgress, Grid, IconButton, Input, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, ListSubheader, Sheet, Stack, Textarea, Typography} from "@mui/joy";
import {FileCopy, Download, Upload, FindInPage, FindInPageOutlined, FindReplaceOutlined, Cancel} from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {DocSTypeService, DocTypeService} from "../../../types/DocsType";
import {useTranslation} from "react-i18next";
import React from "react";
import {apiDocs} from "../../../net/rest/ApiDocs";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {ListDecorator} from "../../../components/ListDecorator";
import {dateReFormat, DateToEpoch, dateToMs_String, formatBytes, MsTodate, UTCTodate} from "../../../utils";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ctx from "../../../store/CurrentContext";
import AttachMsg from "../../../types/AttachMsgType";
import { EditDialog, EditDialogDescriptor, initEditDialogDescriptor } from "../../../components/EditDialog";
import { identity, ustring } from "../../../types/Alias";
import { FileWatcherEventKind } from "typescript";

const ContentPanel = observer(() => {


  const {t} = useTranslation();
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [showStatusLeft, setShowStatusLeft] = React.useState<boolean>(true);
  const [fileLoading, setfileLoading] = React.useState<Map<number, undefined>>(new Map());
  const refDialog = React.useRef<HTMLInputElement>(null);
  const [prepareMakeLoading, setprepareMakeLoading] = React.useState<boolean>(false);
  const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handleResultDialog));
  const [findAreaValue, setfindAreaValue] = React.useState<string>("");
  
   //Delete
  function deleteRecord(fileName: String, fileId: number) {
    docsStore.SelectedIDDoc = fileId;
    dlgDescriptor.title = t("Delete_Title");
    dlgDescriptor.cancel = t("Button_Cancel");
    dlgDescriptor.apply = t("Button_Delete");
    dlgDescriptor.text = `${t("Delete_Text")} ${fileName} ?`;
    dlgDescriptor.elements = [];
    dlgDescriptor.anchor = "DEL";
    showDialog();
  }

function closeDialog() {
  setDlgDescriptor((reset: EditDialogDescriptor) => {
      return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
  });
}

function showDialog() {
  setDlgDescriptor((reset: EditDialogDescriptor) => {
      reset.visible = true;
      return {...reset};
  });
}

function handleResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>, linkedresult?: Map<string, Object>) {
  closeDialog();
  // TODO
  if (isapplied) {

      switch (anchor) {
          case "DEL": {         
              apiDocs.deleteDoc(
                  {
                      sucess: (data: any, envoy?: any) => {
                          setShowStatusLeft(false);
                              const selectedDocDelete = docsStore.getDocByID(docsStore.SelectedIDDoc);
                              if (selectedDocDelete) {
                                  docsStore.deleteDoc(selectedDocDelete);
                              }
                              docsStore.SelectedIDDoc = -1;

                      },
                      error: (data: any, envoy?: any) => {
                          setShowStatusLeft(false);
                          currentInfoStore.setInfo("Delete Doc error " + data, "ERROR");
                          docsStore.SelectedIDDoc = -1;
                      },
                  },
                  docsStore.SelectedIDDoc,
              );
              break;
          }
          default: {
              break;
          }
      }

  } 
}
  
  function download(docid: number, docds: string) {
    const y = document.getElementById('blob_link');
    if (document.getElementById('blob_link')) {
        setfileLoading(map => {
            const newmap = new Map(map);
            newmap.set(docid, undefined);
            return newmap;
        });
        apiDocs.downloadDocFile(docid)
            .then((data: any) => {
                const blob_link = document.getElementById('blob_link') as any;
                if (blob_link) {
                    const url = window.URL.createObjectURL(data);
                    blob_link.href = url;
                    blob_link.download = docds;
                    blob_link?.click();
                    window.URL.revokeObjectURL(url);
                    setfileLoading(map => {
                        const newmap = new Map(map);
                        newmap.delete(docid);
                        return newmap;
                    });
                }

            })
            .catch((error: any) => {
                currentInfoStore.setInfo(t("GetDocerror") + error, "ERROR");
            })
    }

}

//Attach
const handleClickAttach = () => {
   refDialog.current?.click();
}

const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
  if (e.target.files) {
    if (e.target.files.length !== 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > 70 * 1024 * 1024) {
          currentInfoStore.setInfo(t("limitAttachSize"));
        } else {
          const uploadFiles: Array <AttachMsg>=[];
          uploadFiles.push({docds: e.target.files[i].name, docid: 1, file: e.target.files[i]});
          setprepareMakeLoading(true);
          apiDocs.postDocAttach(
            {
              sucess: (data: DocTypeService, envoy?: any) => {
                setprepareMakeLoading(false);
                if (envoy){
                  docsStore.addDoc({...data,uploadtime:envoy.uploadtime,lenght:envoy.size}); 
                }               
              },
              error: (data: any, envoy?: any) => {
                 setprepareMakeLoading(false);
                currentInfoStore.setInfo(t("AddDocerror") + data, "ERROR");
              },
              envoy: {
                uploadtime: dateToMs_String(new Date()),
                size: e.target.files[i].size
              }
            },
            [...uploadFiles],
            [["persist", "1"], ["userid", String(ctx.usid)]])
        }
      }

    }
  }
};

function findFiles(elements:React.ChangeEvent<HTMLInputElement>){
  setfindAreaValue(elements.target.value);
}

  function updateDocs() {
    setShowStatusLeft(true);
    apiDocs.getDocs({
      sucess: (data: DocSTypeService): void => {

        data.content.forEach((value: DocTypeService) => {
          docsStore.addDoc(value);
        });
        setShowStatusLeft(true);
      }, error: (data: any): void => {
        setShowStatusLeft(false);
        currentInfoStore.setInfo("Docs refill" + data, "ERROR");
      }
    },
      [["pagesize", "99999"], ["pagenumber", "0"]]

    );
  }

  React.useEffect(() => {
    updateDocs();
    return () => {
    };
  }, [refresh]);



  return (
    <>
      <Sheet
        sx={{
          borderRadius: 'md',
          borderStyle: "solid",
          borderColor: "neutral.softHoverBg",
          borderWidth: 1,
          backgroundColor: 'neutral.softBg',
          p: 1,
          maxHeight: 'var(--Header-height)',

        }}
      >
        <Stack direction="row" spacing={0.5}>


          {/* <IconButton size="sm"
          //onClick={() => pageValue(-999999)} 
          >
            <KeyboardDoubleArrowLeftIcon sx={{fontSize: "14"}} />
          </IconButton>
          <IconButton size="sm"
          // onClick={() => pageValue(-1)} 
          >
            <KeyboardArrowLeftIcon sx={{fontSize: "14"}} />
          </IconButton>
          <Input size="sm" type="text" variant="outlined" sx={{width: 70}}
          // value={pagination ? pagination.page : 1}
          // onChange={(event) => pageValue(0, Number(event.target.value))}
          // endDecorator={<Chip>{pagination ? pagination.pagecount : 1}
          // </Chip>}
          />
          <IconButton size="sm"
          //</Stack>onClick={() => pageValue(1)}

          >
            <KeyboardArrowRightIcon sx={{fontSize: "14"}}
            />
          </IconButton>
          <IconButton size="sm"
          // onClick={() => pageValue(999999)}
          >
            <KeyboardDoubleArrowRightIcon sx={{fontSize: "14"}} />
          </IconButton> */}
          <div>
            <input type="file" ref={refDialog} style={{ display: 'none' }} multiple
              //accept='image/*,documents/*,.png,.jpeg,.web'
              onChange={((e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, "inputLoadFile_"))} />
            <IconButton variant="soft" onClick={handleClickAttach}>
              <Typography color="neutral"
                startDecorator={(!prepareMakeLoading) ? (<></>)
                  : (
                    <CircularProgress color='danger' sx={{ zoom: "50%" }} />
                  )}>Загрузить</Typography>
              <Upload sx={{ transform: `scale(0.8)` }} />
            </IconButton>
          </div>
          <Input 
          placeholder={t("Search")}
          // endDecorator={(findAreaValue==="")?
          // (<></>):
          // (
          //   <IconButton onClick={()=>setfindAreaValue("")}><Cancel/></IconButton>
          // )}
          onChange={(e)=>findFiles(e)}/>
        </Stack>
      </Sheet>

      <Sheet
        sx={{
          height: "calc(100dvh - var(--DowbleHeader-height))",
          display: 'flex',
          flexDirection: 'column',
          overflow: "auto"
        }}
      >
        <ListDecorator>
          <List size="sm" sx={{ml: 3}} >
            {docsStore.Display(findAreaValue).map(doc => {
              return (
                <>
                  <ListItem
                    variant={"plain"}
                    sx={{
                      backgroundColor: "inherit",
                      ml: 0.5,
                      mr: 0.5,
                    }} >
                    <ListItemButton>
                      <Stack direction="row" spacing={1.5}
                      >
                        <Typography
                          level="body-sm"
                          sx={{width: "270px"}}
                          startDecorator={<Chip color="primary">{t('UploadLabel')}</Chip>}
                        >
                          {MsTodate(Number(doc.uploadtime))}
                          {/* {dateReFormat(doc.uploadtime, "yyyy-mm-ddTHH:MM:SS", "dd-mm-yyyy HH:MM")} */}
                        </Typography>
                        <Typography level="body-sm" sx={{
                          width: "350px",
                          fontWeight: "bold"
                        }}
                          startDecorator={<Chip color="primary">{t('File')}</Chip>}
                        >{doc.docds}</Typography>

                        <Typography
                          level="body-sm"
                          sx={{width: "270px"}}
                          startDecorator={<Chip color="primary">{t('Size')}</Chip>}
                        >{formatBytes(doc.lenght)}</Typography>
                        <IconButton variant="soft" onClick={()=>download(doc.docid,doc.docds)}><Download sx={{transform: `scale(0.8)`}} /></IconButton>
                        <IconButton variant="soft" onClick={()=>deleteRecord(doc.docds,doc.docid)}><DeleteOutlineIcon sx={{transform: `scale(0.8)`}} /></IconButton>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                </>
              )
            })}


          </List>
        </ListDecorator>
      </Sheet>
      <a id="blob_link" style={{display: 'none'}}></a>
      <EditDialog descriptor={dlgDescriptor} />
    </>
  )

});

export {ContentPanel};
