import { nustring, ustring} from "../../../../types/Alias";
import {isStringTestJson} from "../../../../utils";

type Localdata = {
    id: string,
    partid: string,
    typeid: string,
    text: string,
    botid: ustring,
    docid: ustring,
    teamid: ustring,
    content?: {x: number, y: number},
    normalized?: string;
}

type Targetdata = {
    botstepid: number
    parentstepid: number,
    textds: string,
    messagetypeid: number,
    botid?: number,
    docid?: number,
    teamid?: number,
    content?: {x: number, y: number},
}

class Treehelper {
    
    public intiIncrement(localData: Map<string, Localdata>):number {
            let i = 0;
            localData.forEach((value) => {i = (Number.parseInt(value.id) > i) ? Number.parseInt(value.id) : i});
            return i;
    }
    
    private undefinedtozero(value: nustring): string {
        if ((value === undefined) || (value === null) ||
            (value.length === 0)) {
            return "0";
        }
        else {
            return value;
        }
    }

    public intialte(data: any,shiftX: number, shiftY: number):Map<string, Localdata> {
        const localData: Map<string, Localdata> = new Map();
    
        if ((data.steps) && (data.steps.length > 0)) {
            data.steps.forEach((step: Targetdata) => {
                localData.set(String(step.botstepid),
                    {
                        id: String(step.botstepid),
                        partid: String(this.undefinedtozero(String(step.parentstepid))),
                        typeid: String(step.messagetypeid),
                        text: String(step.textds),
                        botid: step.botid ? String(step.botid) : undefined,
                        docid: step.docid ? String(step.docid) : undefined,
                        teamid: step.teamid ? String(step.teamid) : undefined,
                        content: step.content ?
                            ((internalData): {x: number, y: number} | undefined => {

                                if ((internalData["x"] === undefined) || (internalData["y"] === undefined)) {
                                    return undefined;
                                }
                                else {
                                    return {x: Number(internalData.x), y: Number(internalData.y)}
                                }
                            })(step.content) : undefined,
                    }
                );
            });
            this.normalize(localData);
            this.calcXYPositions(localData,shiftX, shiftY)
        }
        return localData;
    }

    private normalize(localData: Map<string, Localdata>) {

        let tmp: Map<string, string> = new Map();
        localData.forEach(
            (value: Localdata, key: string, map) => {
                if (value.typeid === "0") {
                    tmp.set(value.partid, value.id);
                    value.normalized = value.partid;
                    map.set(key, value);
                }
            }
        )
        localData.forEach(
            (value: Localdata, key: string, map) => {
                if (value.typeid !== "0") {
                    let newpid = tmp.get(value.partid);
                    if (newpid !== undefined) {
                        value.normalized = newpid;
                        map.set(key, value);
                    }
                    else {
                        value.normalized = value.partid;
                        map.set(key, value);
                    }
                }
            }
        )
    }

    private calcXYPositionsNext(localData: Map<string, Localdata>,shiftX: number, shiftY: number, parentkey: string,
        offsetX: number, offsetY: number): number {

        const nextList: string[] = [];
        localData.forEach((value) => {
            if (value.normalized === parentkey) {
                nextList.push(value.id);
            }
        }
        );
        offsetX += shiftX;
        nextList.forEach((key) => {
            offsetY += shiftY;
            if (localData.has(key)) {
                const v = localData.get(key);
                if ((v) && (v.content === undefined))
                    v.content = {x: offsetX, y: offsetY};
            }
            offsetY = this.calcXYPositionsNext(localData,shiftX, shiftY,
                key, offsetX, offsetY);
        }
        );
        return offsetY;
    }


    private calcXYPositions(localData: Map<string, Localdata>,shiftX: number, shiftY: number) {
        let startlist: string[] = [];
        localData.forEach((value) => {
            if (value.normalized === "0") {
                startlist.push(value.id);
            }
        }
        );
        let offsetX: number = 0;
        let offsetY: number = -shiftY;
        startlist.forEach((key) => {
            offsetY += shiftY;
            if (localData.has(key)) {
                const v = localData.get(key);
                if ((v) && (v.content === undefined))
                    v.content = {x: offsetX, y: offsetY};
            }
            offsetY = this.calcXYPositionsNext(localData,shiftX, shiftY,
                key, offsetX, offsetY);
        }
        );
    }
}

const treehelper = new Treehelper();

export {treehelper};
export type {Localdata, Targetdata}


