import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {Chip, CircularProgress, IconButton, Input, List, ModalDialogProps, Sheet, Stack} from "@mui/joy";
import {ListDecorator} from "../../../components/ListDecorator";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MyDialogItem from "./components/MyDialogItem";
import dialogsStore from "../../../store/DialogsStore";
import {DialogSType, DialogType} from "../../../types/DialogType";




const DialogsList = observer(( props:{checkLoading:()=>boolean}  ) => {

 

    return (<>
        <Sheet
            sx={{
                width: 300,
                height: 'calc(100dvh - var(--Button-height))',
                display: 'flex',
                flexDirection: 'column',

            }}
        >


            {props.checkLoading() ? (
                <>
                    <CircularProgress color="primary" />
                </>
            )
                :
                (
                    <ListDecorator>
                        <List size="sm" >
                            {dialogsStore.Display().map(
                                (dialog: DialogType) => {
                                    return (
                                        <MyDialogItem dialog={dialog} />
                                    );
                                }
                            )}

                        </List>
                    </ListDecorator>
                )
            }

        </Sheet >



    </>);
});
export default DialogsList;
