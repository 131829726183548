import {Chip, IconButton, Input, ModalDialogProps, Sheet, Stack, Typography} from "@mui/joy";
import {observer} from "mobx-react";
import {DialogsCategs} from "./DialogsCategs";
import DialogsList from "./DialogsList";
import * as React from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SyncUserSearch from "./components/SyncUserSearch";
import {Environment} from "../../../environment";
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import {apiDialogs} from "../../../net/rest/ApiDialogs";
import dialogsStore from "../../../store/DialogsStore";
import ctx from "../../../store/CurrentContext";
import {DialogStatus, Priority, TypesOfUsers} from "../../../store/Enumerations";
import {useTranslation} from "react-i18next";
import {ustring} from "../../../types/Alias";
import usersStore from "../../../store/UsersStore";
import teamStore from "../../../store/TeamStore";
import {DialogSType} from "../../../types/DialogType";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {UserType} from "../../../types/UserType";
import {TeamType} from "../../../types/TeamType";

type Options = {
    dialogid?: number | undefined,
    userid?: number | undefined,
    username?: string | undefined,
    teamid?: number | undefined,
    assignedid?: number | undefined,
    priorityid?: number | undefined,
    statusid?: number | undefined,
    closedreasonid?: number | undefined,
    offsetdate: string,
    sortfield: string,
    sortorder: number,
    size: number,
}

type Pagination = {
    page: number,
    pagecount: number
}

function dateDefInput(op: Options | undefined) {
    if (op) {
        if (op.offsetdate)
            return op.offsetdate;
    }

    const date = new Date();
    date.setDate(date.getDate() - (Environment.OFFSET_DAYS ? Number(Environment.OFFSET_DAYS) : 3));
    const year = date.getFullYear();
    const month =
        (date.getUTCMonth() + 1).toString().length < 2
            ? `0${date.getUTCMonth() + 1}`
            : date.getUTCMonth() + 1;
    const day =
        date.getUTCDate().toString().length < 2
            ? `0${date.getUTCDate()}`
            : date.getUTCDate();
    return `${year}-${month}-${day}`;
}


const DialogsLayout = observer(() => {

    const {t} = useTranslation();


    const [loading, setLoading] = React.useState<boolean>(false);

    function checkLoading()
    {
        return loading;
    } 

    const [pagination, setPagination] = React.useState<Pagination>();
    function getPagination() {
        return pagination || {page: 1, pagecount: 1};
    }
    function getPaginationInit() {
        return {page: 1, pagecount: 1};
    }
    function getPaginationFrom(p: any | undefined, pc: any | undefined) {
        return {
            page: p ? Number(p) : 1,
            pagecount: pc ? Number(pc) : 1
        }
    }


    const [userSearchLayout, setUserSearchLayout] =
        React.useState<ModalDialogProps['layout'] | undefined>(undefined);
    const [userSearchLocator, setUserSearchLocator] =
        React.useState<{partValue: string, pushback: (code: string, value: string) => void}>
            ({partValue: "", pushback: (code: string, value: string) => {}});

    function getLayout(): (ModalDialogProps['layout'] | undefined) {
        return userSearchLayout;
    }

    function setLayout(layout: ModalDialogProps['layout'] | undefined) {
        setUserSearchLayout(layout);
    }


    const [options, setOptions] = React.useState<Options>();

    function getOptions() {
        return options || reOption();
    }
    function optEmptyToUndefidedStr(value: string | undefined): string | undefined {
        if ((value !== undefined) && (value.trim().length < 1)) {
            return undefined;
        } else {
            return value;
        }
    }
    function optEmptyToUndefidedNumb(value: string | undefined): number | undefined {
        if ((value !== undefined) && (value.trim().length < 1)) {
            return undefined;
        } else {
            return Number(value);
        }
    }


    function getUserLocator(): string {
        return userSearchLocator.partValue;
    }

    function setTargetUser(id: string, name: string) {
        userSearchLocator.pushback(id, name);
    }

    function reOption(): Options {
        const categotyItem = dialogsStore.DialogPreSetSection
        return {
            size: options ? options.size : Environment.DIALOGSIZE,
            sortfield: "lastupdate",
            sortorder: 1,
            offsetdate: dateDefInput(options),
            ...(categotyItem.section === "ITEM" && categotyItem.item === "MY")
                ? {assignedid: Number(ctx.usid)} : {},
            ...(categotyItem.section === "ITEM" && categotyItem.item === "ARH")
                ? {statusid: DialogStatus.Close} : {statusid: DialogStatus.Open},
            ...(categotyItem.section === "KEY") ? {teamid: Number(categotyItem.item)} : {},
        }
    }

    //Dialogs state
    const [dlgDescriptor, setDlgDescriptor] =
        React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

    //Dialogs close
    function closeDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
        });
    }

    //Dialogs show
    function showDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            reset.visible = true;
            return {...reset};
        });
    }

    //Dialogs process result
    function handlResultDialog(anchor: string | undefined, isapplied: boolean, result?: Map<string, ustring>) {
        closeDialog();
        if (isapplied) {
            switch (anchor) {
                case "SET":
                    const newOption: Options = {
                        ...getOptions()
                        , ...result?.has("teamid") ? {teamid: optEmptyToUndefidedNumb(result.get("teamid"))} : {}
                        , ...result?.has("assignedid") ? {assignedid: optEmptyToUndefidedNumb(result.get("assignedid"))} : {}
                        , ...result?.has("priorityid") ? {priorityid: optEmptyToUndefidedNumb(result.get("priorityid"))} : {}
                        , ...result?.has("offsetdate") ? {offsetdate: optEmptyToUndefidedStr(result.get("offsetdate"))} : {}
                        , ...result?.has("sortfield") ? {sortfield: optEmptyToUndefidedStr(result.get("sortfield"))} : {}
                        , ...result?.has("sortorder") ? {sortorder: optEmptyToUndefidedNumb(result.get("sortorder"))} : {}
                        , ...result?.has("userid") ? {userid: optEmptyToUndefidedNumb(result.get("userid"))} : {}
                        , ...(result?.get("userid") && result?.has("username"))
                            ? {username: optEmptyToUndefidedStr(result.get("username"))} : {}


                    }
                    if (result?.has("size") && result.get("size")) {
                        newOption.size = Number(result.get("size"));
                    }
                    setOptions(newOption);
                    loadDialogs(reguestDialogs(newOption, getPagination()));
                    break;

                default:
                    break;
            }
        }
    }

    function reguestDialogs(opt: Options, pgn: Pagination): [string, string][] {
        const queryparams: [string, string][] = [];

        queryparams.push(["statusid", String(opt.statusid)]);
        queryparams.push(["pagesize", String(opt.size)]);
        queryparams.push(["pagenumber", String(pgn.page - 1)]);

        if (opt.assignedid)
            queryparams.push(["assignedid", String(opt.assignedid)])
        if (opt.teamid)
            queryparams.push(["teamid", String(opt.teamid)])
        if (opt.dialogid)
            queryparams.push(["dialogid", String(opt.dialogid)]);
        if (opt.userid)
            queryparams.push(["userid", String(opt.userid)]);
        if (opt.offsetdate) {
            queryparams.push(["offsetdate", String(Math.floor(new Date(opt.offsetdate).getTime() / 1000))]);
        }
        if (opt.priorityid)
            queryparams.push(["priorityid", String(opt.priorityid)]);
        if (opt.closedreasonid)
            queryparams.push(["closedreasonid", String(opt.closedreasonid)]);
        if (opt.sortfield)
            queryparams.push(["sortfield", String(opt.sortfield)]);
        if (opt.sortorder)
            queryparams.push(["sortorder", String(opt.sortorder)]);

        return queryparams;
    }
    function generateFilter() {

        dlgDescriptor.title = t("Filter");
        dlgDescriptor.orientation = "horizontal";
        dlgDescriptor.elements = [
            {
                format: "INPUT", key: "username", search_key: "userid", title: t("User"), sxLable: {minWidth: 100}, sxInput: {minWidth: 150},
                search: (value: ustring, pushback: (code: string, value: string) => void) => {
                    setUserSearchLocator({partValue: value ? value : "", pushback: pushback});
                    setLayout('center');

                },
                currentValue: options?.username,
                currentSearchValue: String(options?.userid)
            }];
        if (!(dialogsStore.DialogPreSetSection.section === "ITEM" && dialogsStore.DialogPreSetSection.item === "MY"))
            dlgDescriptor.elements.push(
                {
                    format: "SELECT", key: "assignedid", title: t("Agent"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                    currentValue: options?.assignedid ? String(options.assignedid) : "",
                    source: [
                        {code: "", value: t('Any')},
                        {code: "-1", value: t('NoAssigned')},
                        {code: "-2", value: "["+t('Bot')+"]"},
                        ...usersStore.Display((value) => value.usertypeid === TypesOfUsers.Agents).map((user: UserType) => {
                            return {code: String(user.userid), value: user.userds}
                        }
                        )]
                });

        if (!(dialogsStore.DialogPreSetSection.section === "KEY"))
            dlgDescriptor.elements.push(
                {
                    format: "SELECT", key: "teamid", title: t("Team"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                    currentValue: options?.teamid ? String(options.teamid) : "",
                    source: [
                        {code: "", value: t('AnyF')},
                        ...teamStore.getTeams().map((team: TeamType) => {
                            return {code: String(team.teamid), value: team.teamds}
                        }
                        )]
                });

        dlgDescriptor.elements.push(
            {
                format: "SELECT", key: "priorityid", title: t("Priority"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.priorityid ? String(options.priorityid) : "",
                source: [
                    {code: "", value: t('Any')},
                    {code: String(Priority.Cricical), value: t("en_priority_" + Priority.Cricical)},
                    {code: String(Priority.Hight), value: t("en_priority_" + Priority.Hight)},
                    {code: String(Priority.Medium), value: t("en_priority_" + Priority.Medium)},
                    {code: String(Priority.Low), value: t("en_priority_" + Priority.Low)},

                ]
            },
        );
        dlgDescriptor.elements.push(
            {
                format: "DATE", key: "offsetdate", title: t("Date"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.offsetdate ? options.offsetdate : dateDefInput(options)
            });
        dlgDescriptor.elements.push(
            {
                format: "SELECT", key: "sortfield", title: t("Sort"),
                sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.sortfield ? options.sortfield : "lastupdate",
                source: [
                    {code: "lastupdate", value: "date"},
                    {code: "dialogid", value: "dialog"},
                    {code: "userid", value: "user"},
                    {code: "teamid", value: "team"},
                    {code: "assignedid", value: "assignee"},
                    {code: "priorityid", value: "priority"},
                    {code: "statusid", value: "status"},
                ]
            }
        );
        dlgDescriptor.elements.push(
            {
                format: "SELECT", key: "sortorder", title: t("Order"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.sortorder ? String(options.sortorder) : "0",
                source: [
                    {code: "0", value: "ASC"},
                    {code: "1", value: "DESC"},

                ]
            },
        );
        dlgDescriptor.elements.push(
            {
                format: "SELECT", key: "size", title: t("rowCount"), sxLable: {minWidth: 100}, sxInput: {minWidth: 205},
                currentValue: options?.size ? String(options.size) : String(Environment.DIALOGSIZE),
                source: [
                    {code: "10", value: "10"},
                    {code: "20", value: "20"},
                    {code: "40", value: "40"},
                    {code: "50", value: "50"},
                    {code: "70", value: "70"},
                    {code: "100", value: "100"},
                    {code: "200", value: "200"}

                ]
            },
        );

        dlgDescriptor.anchor = "SET";
        showDialog();

    }

    function pageValue(delta: number, n?: number) {

        const pag = {...getPagination()};
        const nn = (n) ? (n + delta) : pag.page + delta;
        pag.page = nn <= 0 ? 1 : ((nn > pag.pagecount) ? pag.pagecount : nn)
        setPagination(pag);
        loadDialogs(reguestDialogs(getOptions(), pag));
    }

    function loadDialogs(queryparams: [string, string][]) {
        setLoading(true);
        apiDialogs.getDialogs({
            sucess: (data: DialogSType) => {
                setLoading(false);
                setPagination(
                    getPaginationFrom(Number(data.pagenumber) + 1, data.totalpages)
                );
                dialogsStore.addDialogBatchBreed(data);
            },
            error: (data: any) => {
                setLoading(false);
                setPagination(getPaginationInit());
                currentInfoStore.setInfo("Dialogs " + data, "ERROR");

            }
        }, queryparams);

    }

    React.useEffect(() => {
        const opt: Options = reOption();
        setOptions(opt);
        loadDialogs(reguestDialogs(opt, getPaginationInit()));
        return () => {

        };
    }, [dialogsStore.tailPreSetSection]);


   

    return (
        <>
            <Sheet>
                <Sheet
                    sx={{
                        borderRadius: 'md',
                        borderStyle: "solid",
                        borderColor: "neutral.softHoverBg",
                        borderWidth: 1,
                        backgroundColor: 'neutral.softBg',
                        p: 1,
                        maxHeight: 'var(--Header-height)',

                    }}
                >
               
                        <Stack direction="row" spacing={0.5}>
                            <IconButton size="sm" color="primary" variant="outlined"
                            onClick={() => {generateFilter()}}
                            >
                                <FilterAltIcon />
                            </IconButton>
        
                            <Typography  variant="soft" color="primary" 
                            endDecorator={<Chip color="success">{options?.offsetdate}</Chip>}
                            level="body-sm"
                            >{t('Chatsfrom')}</Typography>
                             
                            <IconButton size="sm" onClick={() => pageValue(-999999)} >
                                <KeyboardDoubleArrowLeftIcon sx={{fontSize: "14"}} />
                            </IconButton>
                            <IconButton size="sm" onClick={() => pageValue(-1)} >
                                <KeyboardArrowLeftIcon sx={{fontSize: "14"}} />
                            </IconButton>
                            <Input size="sm" type="text" variant="outlined" sx={{width: 70}}
                                value={pagination ? pagination.page : 1}
                                onChange={(event) => pageValue(0, Number(event.target.value))}
                                endDecorator={<Chip>{pagination ? pagination.pagecount : 1}</Chip>}
                            />
                            <IconButton size="sm" onClick={() => pageValue(1)} >
                                <KeyboardArrowRightIcon sx={{fontSize: "14"}} />
                            </IconButton>
                            <IconButton size="sm" onClick={() => pageValue(999999)} >
                                <KeyboardDoubleArrowRightIcon sx={{fontSize: "14"}} />
                            </IconButton>

                    </Stack>
                </Sheet>
                <Sheet
                    sx={{
                        display: 'grid',
                        mx: 'auto',
                        gridTemplateColumns: {
                            xs: 'minmax(min-content, min(50%, 150px)) 1fr',
                            sm: 'minmax(min-content, min(50%, 200px)) 1fr',
                            md: 'minmax(min-content, min(50%, 200px)) 1fr',
                        }
                    }}>
                    <Sheet
                        sx={{
                            position: 'sticky',
                            zIndex: 100,
                            width: '100%',
                            //  top: 'var(--Header-height)',

                        }}
                    >
                        <DialogsCategs />


                    </Sheet>
                    <Sheet
                        sx={{
                            position: 'sticky',
                            zIndex: 100,
                            width: '100%',
                            // top: 'var(--Header-height)',
                        }}
                    >
                        <DialogsList checkLoading={checkLoading}/>


                    </Sheet>
                </Sheet>
            </Sheet>

            <EditDialog descriptor={dlgDescriptor} />
            <SyncUserSearch
                getUserLocator={getUserLocator}
                setTargetUser={setTargetUser}
                getLayout={getLayout}
                setLayout={setLayout}
            />
        </>
    )
});

export {DialogsLayout};
export {};