import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import {Button, Divider, Dropdown, Menu, MenuButton, MenuItem, Stack, Typography} from '@mui/joy';

//import Title from '../components/AppTitle';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import AdbIcon from '@mui/icons-material/Adb';
import {useTranslation} from "react-i18next";
import currentPageStore from '../../store/CurrentPageStore';
import ToggleTheme from './ToggleTheme';
import ToggleLocale from './ToggleLocale';
import {observer} from 'mobx-react-lite';
import SvgComponent from '../../components/SvgComponent';
import ToggleDebug from './ToggleDebug';
import lo from '../../store/Logging';
import ctx from '../../store/CurrentContext';




const HeadBar = observer(() => {

    const {t} = useTranslation();
if ((!lo.RestEnable )&& (!lo.WsEnable) && (currentPageStore.Value ==="BUG"))
    currentPageStore.Value ="CHAT";

    return (
        <Sheet
        
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: 'var(--Header-height)',
                zIndex: 100,
                p: 2,
                gap: 1,
                borderBottom: '1px solid',
                borderColor: 'background.level3',
                boxShadow: 'sm',
                backgroundColor: "background.level1"
            }}

        >
            <Stack direction="row" spacing={1}>
            <SvgComponent/>
            <Stack direction="column">

            <Typography
                    fontSize="md"
                    level="body-xs"
                    sx={{pl:  0.1, pt: 0.1
                    }}
                    textColor={'primary'}
                >
                    {t("title")}


                </Typography>
                <Typography
                    fontSize="xs"
                    level="body-xs"
                    sx={{pl:  0.1, pt: 0.1
                    }}
                    textColor={"text.tertiary"}
                >
                    Вы вошли как {ctx.name}
                </Typography>
                
            </Stack>
  
            </Stack>
            <Stack direction="row" sx={{color: "text.tertiary"}}>
                <Button variant={(currentPageStore.Value ==="CHAT")?"solid":"plain"} startDecorator={<ForumOutlinedIcon />} onClick={() => {currentPageStore.Value = "CHAT"}}>{t("hb_chat_m")}</Button>
                <Button variant={(currentPageStore.Value ==="BOT")?"solid":"plain"}  startDecorator={<DynamicFormOutlinedIcon />} onClick={() => {currentPageStore.Value = "BOT"}}>{t("hb_bot_m")}</Button>
                <Button variant={(currentPageStore.Value ==="TEAM")?"solid":"plain"}  startDecorator={<Groups2OutlinedIcon />} onClick={() => {currentPageStore.Value = "TEAM"}}>{t("hb_team_m")}</Button>
                <Button variant={(currentPageStore.Value ==="LIB")?"solid":"plain"}  startDecorator={<CollectionsBookmarkOutlinedIcon />} onClick={() => {currentPageStore.Value = "LIB"}}>{t("hb_lib_m")}</Button>
                {/* <Button variant={(currentPageStore.Value ==="REPS")?"solid":"plain"}  startDecorator={<QueryStatsOutlinedIcon />} onClick={() => {currentPageStore.Value = "REPS"}}>{t("hb_rep_m")}</Button> */}
                { (lo.RestEnable || lo.WsEnable) &&
                (<Button variant={(currentPageStore.Value ==="BUG")?"solid":"plain"} color='danger' startDecorator={<AdbIcon />} onClick={() => {currentPageStore.Value = "BUG"}}>Logging</Button>)
}
                <Divider orientation="vertical" sx={{mr: 1, ml: 2}} />
                <ToggleTheme sx={{ml: 1, width: 35, }} />
                <ToggleLocale sx={{ml: 1, width: 35}} />
                <ToggleDebug sx={{ml: 1, width: 35}} />
            </Stack>


        </Sheet>
    );
});

export default HeadBar;