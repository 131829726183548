import {makeAutoObservable} from "mobx";
import {TeamType}  from "../types/TeamType";



class TeamStore {
    private $teams: TeamType[] = [];
    private $selected_idteam: number = -1;

    public constructor() {
        makeAutoObservable(this);
    }

    /** возвращает список команд */
    public getTeams(): TeamType[] {
        return this.$teams;
    }

    /** возвращает список команд по списку id
    * используется фильтрация массива там где у элемента id в списке ids переданной в сигнатуре
   */
    public getTeamByIDs(ids_list: number[]): TeamType[] {
        return this.$teams.filter((value: TeamType) => {
            return ids_list.indexOf(value.teamid) >= 0;
        });
    }

    public getTeamByID(id: number): TeamType | undefined {
        const teams = this.getTeamByIDs([id]);
        return teams.length === 0 ? undefined : teams[0];
    }

    /** возвращает список команд по списку names
    * используется фильтрация массива 
   */
    public getTeamByNames(names_list: string[]): TeamType[] {
        return this.$teams.filter((value: TeamType) => {
            return names_list.indexOf(value.teamds) >= 0;
        });

    }

    public getTeamByName(name: string | any): TeamType | undefined {
        const user = this.getTeamByNames([name]);
        return user.length === 0 ? undefined : user[0];
    }

    /** чистит список 
   */
    public clearTeams() {
        this.$selected_idteam = -1;
        this.$teams = [];

    }

   
    /** add Team 
    */
    public addTeam(value: TeamType) {
        let i: number = this.$teams.findIndex((valueF: TeamType) => valueF.teamid === value.teamid);
        if (i >= 0) {
            this.$teams[i] = {...value};
        } else {
            this.$teams.push(value);
        }

    }

    /** удаляет команды 
    */
    public deleteTeams(teams: TeamType[]) {
        teams.forEach((value: TeamType) => {
            let i: number = this.$teams.findIndex((valueF: TeamType) => valueF.teamid === value.teamid);
            if (i >= 0) {
                this.$teams.splice(i, 1);
            }
        });
    }
    /** удаляет команду
        */
    public deleteTeam(teams: TeamType) {

        let i: number = this.$teams.findIndex((valueF: TeamType) => valueF.teamid === teams.teamid);
        if (i >= 0) {
            this.$teams.splice(i, 1);
        }
    }

    //get by selected teamid
    public get SelectedIdteam(): number {
        return this.$selected_idteam;
    }
    //set selected by teamid
    public set SelectedIdteam(value: number) {
        this.$selected_idteam = value;
    }
}

const teamStore = new TeamStore();
export default teamStore;