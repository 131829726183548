import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import {Box, CssBaseline, Typography, CircularProgress, Stack} from '@mui/joy';
import {BaseStyles} from './BaseStylesUtils';
import HeadBar from './head/HeadBar';
import BodyLayout from './body/BodyLayout';
import BaseInfo from './BaseInfo';
import {ContextState, ContextStateExt} from '../store/CurrentContext';
import ReportIcon from '@mui/icons-material/Report';
import {runSocket, autoDefferedProcess} from '../net/socket/Sock';
import ctx from '../store/CurrentContext';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

export default function BaseLayout() {

  const [contetState, setContextStateExt] = React.useState<ContextStateExt>({state: ContextState.LOADING, extratext: []})


  React.useEffect(() => {
    setContextStateExt({state: ContextState.LOADING, extratext: []});
    ctx.intitContext(null, /*window.callMeID(),*/(result: ContextStateExt) => {
      setContextStateExt(result);
      if (result.state === ContextState.READY) {
        runSocket(0);
        autoDefferedProcess();
      }
    })

  }, [])



  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <BaseStyles />
      <Box sx={{display: 'flex', minHeight: '100dvh'}}>
        {(contetState.state !== ContextState.READY) ? (
          <>
            <Box sx={{
              display: 'flex', width: '50%',
              alignItems: "center",
              justifyContent: "center",
              margin: "auto"
            }}>
              <Stack direction="column">
                <Typography
                  fontWeight="md"
                  fontSize="md"
                  noWrap={false}
                  overflow="auto"
                  startDecorator={
                    (contetState.state === ContextState.LOADING) ? (<CircularProgress size="md" />) : (<ReportIcon />)
                  }
                >
                  {(contetState.state === ContextState.LOADING) && (<>Определение пользователя ...</>)}
                  {(contetState.state === ContextState.SECERROR) && (<>Ошибка определения пользователя, войдите через SFA повторно</>)}
                  {(contetState.state === ContextState.ERROR) && (<>Ошибка определения пользователя</>)}

                </Typography>
                {contetState.extratext.map(line => {
                  return (<>
                    <Typography
                      fontWeight="xs"
                      fontSize="xs"
                      noWrap={false}
                      pl={3}
                      overflow="auto"
                      startDecorator={<RunningWithErrorsIcon />}
                    >{line}</Typography>
                  </>);
                }

                )}
              </Stack>
            </Box>

          </>
        ) : (
          <>
            <HeadBar />
            <BodyLayout />
            <BaseInfo />
          </>

        )}

      </Box>
    </CssVarsProvider >

  );
}

