import {observer} from "mobx-react-lite";
import MsgsHead from "./msgs/MsgsHead";
import {Box, Sheet, Stack} from "@mui/joy";
import MsgsBody from "./msgs/MsgsBody";
import MsgsBottom from "./msgs/MsgsBottom";
import React from "react";
import dialogsStore from "../../../store/DialogsStore";
import { MessageType } from "../../../types/MessageType";
import ctx from "../../../store/CurrentContext";


const ContentPanel = observer(() => {
    const [internalReplyUUID, setInternalReplyUUID] = React.useState< string|undefined>(undefined);

    function  setReplyUUID(uuid:string|undefined)
    {
        setInternalReplyUUID(uuid);
    }

    function  getReplyUUID():string | undefined
    {
        return internalReplyUUID;
    }


    return (
        <Sheet
            sx={{
                //height: { xs: 'calc(100dvh - var(--Header-height))', lg: '100dvh' },//***fuckbar
                height: "calc(100dvh - var(--Header-height))",
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.level2',
            }}
        >
            <MsgsHead />
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    minHeight: 0,
                    px: 2,
                    py: 3,
                    overflowY: 'scroll',
                    flexDirection: 'column-reverse',
                }}
            >
                <Stack spacing={2} justifyContent="flex-end">
                    <MsgsBody setReplyUUID={setReplyUUID} />
                </Stack>
            </Box>
            {dialogsStore.eligibleForUser(ctx.localUser, dialogsStore.Selected) && (
                <MsgsBottom getReplyUUID={getReplyUUID} setReplyUUID={setReplyUUID}/>
            )
            }
        </Sheet>

    );

});
export {ContentPanel};
