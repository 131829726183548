import {makeAutoObservable} from "mobx";
import {MessageSType, MessageType} from "../types/MessageType";
import {UserType} from "../types/UserType";
import usersStore from "./UsersStore";
import dialogsStore from "./DialogsStore";
import ctx from "./CurrentContext";
import {apiMessages} from "../net/rest/ApiMessages";
import currentInfoStore from "./CurrentInfoStore";
import {CandidateState, TypesOfUsers} from "./Enumerations";
import {DateToEpoch} from "../utils";
import { Public } from "@mui/icons-material";
import AttachMsgType from "../types/AttachMsgType";

export type StoredMessageType =
    MessageType & {candidate?: number} & {uuid: string};
    


const $t = "t$", $m = "m$";

class MessagesStore {



    private $messages: Map<string, StoredMessageType> = new Map();


    public constructor() {
        makeAutoObservable(this);
    }


    public get(uuid:string|undefined):MessageType|undefined
    {
        return (uuid)?(this.$messages.get(uuid) as  MessageType):undefined;
    }

    public addMessage(value: MessageType, candidate: number):string {
        let uuid: string = "";
        if (candidate === CandidateState.NO) {

            uuid = $m + value.messageid;
            // delete temp message 
            // eslint-disable-next-line eqeqeq
            if (value.sender.userid == Number(ctx.usid)) {
                if ((value.trackingid) && (value.trackingid.length > 0)) {
                    if (this.$messages.has($t + value.trackingid)) {
                        this.$messages.delete($t + value.trackingid);
                    }
                }
            }
        }
        else {
            uuid = $t + value.trackingid;
        }
        this.$messages.set(uuid, {...value, candidate: candidate, uuid: uuid})
        return uuid;
    }

    public Display(chatid: number | undefined) {
        const filtered = Array.from(this.$messages.values())
            .filter((v: MessageType) => v.dialogid === chatid);
        return filtered.sort(
            (a: MessageType, b: MessageType) => 
                a.senttime - b.senttime ||
            a.messageid - b.messageid
            
        );
    }


    public findMinMessageID(chatid: number | undefined)
    {
        if (chatid) {

            let find_messageid: number | undefined, senttime: number = 0;
            this.$messages.forEach((value, key) => {
                if (value.dialogid === chatid) {
                   // if (senttime === 0) {
                   if (find_messageid ===undefined){
                        find_messageid = value.messageid;
                        senttime = value.senttime;
                    }
                    else {
                       // if (value.senttime < senttime) {
                        if (value.messageid  <(find_messageid||value.messageid+1)) {
                            find_messageid = value.messageid;
                            senttime = value.senttime;
                        }
                    }
                }
            });
                return find_messageid
            }
        
    } 

    public propogateCandidat(uuid: string, candidate: number) {

        if (this.$messages.has(uuid)) {
            this.$messages.set(uuid,
                {...this.$messages.get(uuid), candidate: candidate} as StoredMessageType);
        }
    }

    public isMe(message: StoredMessageType) {
        // eslint-disable-next-line eqeqeq
        return message.sender.userid == Number(ctx.usid)
    }


    public clearMessages() {
        this.$messages.clear();
    }


    public createMessage(
        dialogid: number,
        messagetypeid: number,
        content: string,
        replymessageid?: number,
        attachments?:
            {
                docds: string,
                docid: number
            }[],
        buttons?:
            {
                botstepid: number,
                botstepds: string
            }[],
        replycontent?:string  
    ): MessageType {
        return {
            messageid: -1,
            dialogid: dialogid,
            sender: {
                userid: Number(ctx.usid),
                userds: ctx.name,
                usertypeid: TypesOfUsers.Agents
            },
            messagetypeid: messagetypeid,
            content: content,
            ...(replymessageid) ? {replymessageid: replymessageid} : {},
            senttime: DateToEpoch(new Date()),
            trackingid: ctx.usid + "$" + Date.now() + "$" + Math.round((Math.random() * 1000)),
            ...((attachments) ? {
                attachments: [
                    ...attachments.map((elem: {docds: string, docid: number}) => {
                        return {docds: elem.docds, docid: elem.docid}
                    })
                ]
            } : {}),
            ...((buttons) ? {
                buttons: [
                    ...buttons.map((elem: {botstepid: number, botstepds: string}) => {
                        return {botstepid: elem.botstepid, botstepds: elem.botstepds}
                    })
                ]
            } : {}),
            ...(replycontent) ? {replycontent: replycontent} : {},

        } as MessageType

    }

}



const messagesStore = new MessagesStore();
export default messagesStore;








