
import GlobalStyles from '@mui/joy/GlobalStyles';

import "../i18n/config";
import { useTranslation } from "react-i18next";

import Cookies from 'universal-cookie'
const cookies = new Cookies();


function BaseStyles() {

    return (
        <GlobalStyles
            styles={(theme) => ({
                ':root': {
                    '--Header-height': '52px',
                    '--Button-height': '112px',
                    '--Bottom-height': '22px',
                    '--Panel-height': '40px',
                    '--DowbleHeader-height': '105px',
                    // >> https://v4.mui.com/ru/customization/breakpoints/
                    // [theme.breakpoints.up('lg')]: {
                    //   '--Header-height': '0px',
                    // },
                },
            })}
        />
    );
}


function setTheme(theme:string)
{
    cookies.set('mytheme', theme);
}
function getTheme(deftheme:string)
{
    let theme:string=cookies.get('mytheme');
    return (theme===undefined)||(theme.trim().length<1)?deftheme:theme;
}


function setLocale(locale:string)
{
    cookies.set('mylocale', locale);
}
function getLocale()
{
    let locale:string=cookies.get('mylocale');
    return (locale===undefined)||(locale.trim().length<1)?undefined:locale;
}



export {BaseStyles,setTheme,getTheme,setLocale,getLocale}