import {RestCallback, genericClient} from "./GenericClient";

class ApiTeams {
    

    api:string=genericClient.setUrl( "/api/teams");

    public getTeams(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[['Content-Type', 'application/json']],callback,undefined,undefined,queryparams);
    }

    public createTeam(callback:RestCallback,body:string)
    {

        genericClient.callHttp(this.api,"POST",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public renameTeam(callback:RestCallback,body:string)
    {

        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public deleteTeam(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"DELETE",[['Content-Type', 'application/json']],callback,undefined,undefined,queryparams);
    }

}
const apiTeams = new ApiTeams();

export {apiTeams};