// Core i18next library.
import i18n, {exists} from "i18next";                      
import { title } from "process";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({              
    lng: "en", 
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          hb_chat_m: "Chats",
          hb_bot_m: "Bots",
          hb_agn_m: "Agents",
          hb_team_m: "Teams",
          hb_lib_m: "Library",
          hb_rep_m: "Reports",
          title: "Chatbot Helpdesk",
          error_capt: "Error",
          info_capt: "Info",
          title_teams: "Teams list",
          title_bots: "Bots list",
          title_user_Teams:"Team members",
          Add_Title: "Add record",
          Add_Text: "Adding new name",
          Edit_Title: "Edit record",
          Edit_Text: "Edit name",       
          Delete_Title: "Delete record",
          Delete_Text: "Delete ",
          Elements_Name_Title: "Name",
          Button_change: "Change",
          Button_Cancel: "Cancel",
          Button_Delete: "Delete",
          Button_ADD: "Add",
          Button_Apply: "Apply",
          title_docs: "Library document list",
          Add_Doc: "New library document",
          Edit_Doc: "Update library document",
          Doc_Filename: "File name",
          Filter:"Filter parameters",
          User:"User",
          Bot:"BOT",
          Agent:"Agent",
          Team:"Team",
          Date:"Date",
          Status:"Status",
          Priority:"Priority",
          Sort:"Sort",
          Order:"Order",
          Tag:"Tag",
          Tags:"Tags",
          RedirectBot:"To Bot",
          RedirectAgent:"To Agent",
          RedirectTeam:"To Team",
          ChatsAll:"All chats",
          ChatsMy:"My chats",
          ChatsArh:"Chats archive",
          ChatsTeams:"My teams",
          ChatsNew:"New",
          TeamMember_Add_Title: "add a user to the team",
          TeamMember_Delete_Title: "remove a user from a team",
          TeamMember_Add_text: "select a user",
          TeamMember_attribute_name: "user",
          Ncapt:"Edit node",
          Ncaptn:"Adding node",
          Ncaptnc:"Adding message node",
          vertical_layout:"vertical",
          horizontal_layout:"horizontal",
          root_add:"Create first node",
          nodes_save:"save all",
          nbotid:"Call bot",
          nteamid:"Call team",
          ndocid:"Attach file",
          nclearrow:"<NO SET>",
          ntext:"Text",
          nopt:"No redirect",
          inputBotName:"Input BOT name",
          exists:"Already exists",
          Add_BotTitle: "Create Bot",
          Edit_BotTitle: "Edit Bot",
          Elements_Call_Title: "Title",
          defaultteam:"Default team",
          Ncopy:"Copy Bot",
          en_priority_1:"Critical",
          en_priority_2:"Hight",
          en_priority_3:"Medium",
          en_priority_4:"Low",
          rowCount:"Row count",
          Redirect_Add_Title:"Redirect to chat",
           Priority_Title:"Priority",
           limitAttachSize: "file size should not exceed 70 megabytes",
           attached:"Attachment",
           GetDocerror:"Error receiving the document ",
           AddDocerror:"Error sending the document ",
           Chatsfrom:"Chats from",
           Replay:"Replay",
            File:"File",
            Any:"[Any]",
            AnyF:"[Any]",
            NoAssigned: "[Not assigned]",
            UploadLabel:"Upload date",
            Size:"Size",
            Search:"Search"
      
  
 


        },
      },
      // ru
      ru: {
        translation: {
          hb_chat_m: "Чаты",
          hb_bot_m: "Боты",
          hb_agn_m: "Агенты",
          hb_team_m: "Команды",
          hb_lib_m: "Библиотека",
          hb_rep_m: "Отчеты",
          title: "Чатбот-центр",
          error_capt: "Ошибка",
          info_capt: "Информация",
          title_teams: "Список команд",
          title_bots: "Список ботов",
          title_user_Teams:"Члены команды",
          Add_Title: "Добавление",
          Add_Text: "Введите имя пользователя",
          Edit_Title: "Редактирование",
          Edit_Text: "Отредактируйте имя",
          Delete_Title: "Удаление",
          Delete_Text: "Удалить ",
		      Elements_Name_Title: "Имя",
          Button_change: "Изменить",
          Button_Cancel: "Закрыть",
          Button_Delete: "Удалить",
          Button_ADD: "Добавить",
          Button_Apply: "Применить",
          Filter:"Параметры фильтра",
          User:"Пользователь",
          Bot:"Бот",
          Agent:"Агент",
          Team:"Команда",
          Date:"Дата",
          Status:"Статус",
          Priority:"Приоритет",
          Sort:"Сортировка",
          Order:"Порядок",
          Tag:"Тег",
          Tags:"Теги",
          RedirectBot:"На Бота",
          RedirectAgent:"На Агента",
          RedirectTeam:"На Команду",
          ChatsAll:"Все чаты",
          ChatsMy:"Чаты со мной",
          ChatsArh:"Архив чатов",
          ChatsTeams:"Мои команды",
          ChatsNew:"Новые",
          TeamMember_Add_Title: "добавить пользователя в команду",
          TeamMember_Delete_Title: "удалить пользователя из команды",
          TeamMember_Add_text: "выберете пользователя",
          TeamMember_attribute_name: "пользователь",
          Ncapt:"Редактирование узла",
          Ncaptn:"Добавление узла",
          Ncaptnc:"Добавление вывода сообщения",
          vertical_layout:"вертикально",
          horizontal_layout:"горизонтально",
          root_add:"Добавить базовый узел",
          nodes_save:"сохранить",
          nbotid:"Передать боту",
          nteamid:"Передать команде",
          ndocid:"Прикрепить файл",
          nclearrow:"<НЕ УСТАНОВЛЕНО>",
          ntext:"Текст",
          nopt:"Без перенаправления",
          inputBotName:"Введите название бота",
          exists:"Уже существует",
          Add_BotTitle: "Добавление Бота",
          Edit_BotTitle: "Редактирование Бота",
          Elements_Call_Title: "Название",
          defaultteam:"Команда по умолчанию",
          Ncopy:"Копировать Бот",
          en_priority_1:"Критичный",
          en_priority_2:"Высокий",
          en_priority_3:"Средний",
          en_priority_4:"Низкий",
          rowCount:"Записей",
          Redirect_Add_Title:"Перенаправить чат",
          Priority_Title:"Приоритет",
          limitAttachSize: "Размер файла не должен превышать 70 Мегабайт",
          attached:"Вложение",
          GetDocerror:"Ошибка получения документа ",
          AddDocerror:"Ошибка отправки документа ",
          Chatsfrom:"Чаты после",
          Replay:"Ответ на",
          File:"Файл",
          Any:"[Любой]",
          AnyF:"[Любая]",
          NoAssigned: "[Не назначен]",
          UploadLabel:"Дата загрузки",
          Size:"Размер",
          Search:"Поиск"
        }, 
      },
    },
  });

export default i18n;