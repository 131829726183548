import {Environment} from "../../environment";
import ctx from "../../store/CurrentContext";
import {ContextNames} from "../../store/CurrentContext";
import lo from "../../store/Logging";
import AttachMsg from "../../types/AttachMsgType";

type RestCallback = {
    envoy?: any;
    sucess: (data: any, envoy?: any) => void;
    error: (data: any, envoy?: any) => void;
}

class GenericClient {


    public prepareCallHttp(
        url: string,
        method: string,
        headers: [string, string][],
        credentials?: RequestCredentials,
        body?: BodyInit,
        queryparams?: [string, string][]
    ): {prep_params: RequestInit, prep_url: string} {

        headers.push([ContextNames.JETTON_HEAD, ctx.token]);

        const params: RequestInit = {
            method: method,
            headers: headers
        };
        if (credentials)
            params.credentials = credentials;
        if (body)
            params.body = body;

        let query: URLSearchParams = new URLSearchParams();
        if (queryparams) {
            for (let i = 0; i < queryparams.length; i++) {
                query.append(queryparams[i][0], queryparams[i][1]);
                //encodeURIComponent(queryparams[i][1]));
            }
            url = url + "?" + query.toString();
        }

        try {
            if (lo.RestEnable) {
                lo.addUrl(headers, url);
            }
        }
        catch (e) {}
        return {prep_params: params, prep_url: url};

    }


    public callHttp(url: string,
        method: string,
        headers: [string, string][],
        callback: RestCallback,
        credentials?: RequestCredentials,
        body?: BodyInit,
        queryparams?: [string, string][]) {

        const {prep_params, prep_url} = this.prepareCallHttp(
            url, method, headers, credentials, body, queryparams
        );
        fetch(prep_url, prep_params)
            .then(response => {
                if (response.ok) {

                    if (response.headers.get("Content-Type")?.includes("application/json")) {
                        return response.text()
                    }
                }
                else {
                    return this.wrapMockingError(response.text(),
                        "Error " + response.status + ":" + response.statusText);

                }
                return JSON.parse("{\"data\":\"error\"}");

            })
            .then(data => {
                let dataJSON = undefined;
                try {
                    dataJSON = JSON.parse(data)
                }
                catch (gotErro) {
                    throw new Error(gotErro + " " + data)
                }
                callback.sucess(dataJSON, callback.envoy)
            }
            )
            .catch(error => {
                callback.error(error + " " + url, callback.envoy);
            })

    }

    public callHttpWait(
        url: string,
        method: string,
        headers: [string, string][],
        queryparams?: [string, string][],
        body?: BodyInit

    ) {
        return this.callHttpWaitPrepred(
            this.prepareCallHttp(
                url, method, headers, undefined, body, queryparams
            )
        )

    }

    public callHttpWaitPrepred(
        prepared: {prep_params: RequestInit, prep_url: string}

    ) {

        return (async () => {
            const response = await fetch(prepared.prep_url, prepared.prep_params);
            if (response.ok) {
                try {
                    const data = await response.json();
                    return data;
                } catch (error) {
                    throw Error(error + "");
                }
            }
            else {
                let unpackError: string | undefined
                try {
                    unpackError = await response.text();
                } catch (error) {
                    throw Error(response.status + "-" + response.statusText);
                }
                throw Error(response.status + "-" + response.statusText + ":" + unpackError);


            }

        })()

    }


    public callHttpWaitBlob(
        url: string,
        method: string,
        queryparams?: [string, string][]
    ) {

        const {prep_params, prep_url} = this.prepareCallHttp(
            url, method, [], undefined, undefined, queryparams
        );
        return (async () => {
            const response = await fetch(prep_url, prep_params);
            if (response.ok) {
                try {
                    const data = await response.blob();
                    return data;
                } catch (error) {
                    throw Error(error + "");
                }
            }
            else {
                let unpackError: string | undefined
                try {
                    unpackError = await response.text();
                } catch (error) {
                    throw Error(response.status + "-" + response.statusText);
                }
                throw Error(response.status + "-" + response.statusText + ":" + unpackError);
            }

        })()
    }




    public callHttpPostFile2(url: string,
        method: string,
        callback: RestCallback,
        files: AttachMsg[],
        parts?: [string, string][]) {

        const formData = new FormData();
        files.forEach((file: AttachMsg) => {
            if (file.file) {
                formData.append("file", file.file)
            }
        });
        parts?.forEach(e => {
            formData.append(e[0], e[1]);
        })

        const {prep_params, prep_url} = this.prepareCallHttp(
            url, method, [], undefined, formData, undefined
        );

        fetch(prep_url, prep_params)
            .then(response => {
                if (response.ok)//200
                {
                    return response.json();
                }
                else {

                    return this.wrapMockingError(response.text(),
                        "Http Post File error:" + response.status + ":" + response.statusText);

                }
            }
            )
            .then(data =>
                callback.sucess(data, callback.envoy)
            )
            .catch(error => {
                callback.error(error, callback.envoy);
            })
    }


    public callHttpGetFile(
        url: string,
        method: string,
        headers: [string, string][],
        callback: RestCallback,
        credentials?: RequestCredentials,
        body?: BodyInit,
        queryparams?: [string, string][]
    ) {

        const {prep_params, prep_url} = this.prepareCallHttp(
            url, method, headers, credentials, body, queryparams
        );
        fetch(prep_url, prep_params)
            .then(response => {
                if (response.ok)//200
                {
                    const blob = response.blob();
                    return blob;
                }
                else {
                    return this.wrapMockingError(response.text(),
                        "Http Get File error:" + response.status + ":" + response.statusText);
                }
            }
            )
            .then(data =>
                callback.sucess(data, callback.envoy)
            )
            .catch(error => {
                callback.error(error, callback.envoy);
            })
    }

    public setUrl(path: string): string {
        return Environment.URL_API + path;
    }


    private wrapMockingError(wrappedPrommise: Promise<any>,
        text: string
    ) {
        return new Promise((resolve, reject) => {
            wrappedPrommise
                .then(result => {
                    reject(text + ":" + result);
                })
                .catch(error => {
                    reject(text);
                });
        });
    }

}

const genericClient = new GenericClient();

export {genericClient};
export type {RestCallback};
