import {RestCallback, genericClient} from "./GenericClient";

class ApiDialogs {

    api:string=genericClient.setUrl( "/api/dialogs");

    public getDialogs(callback:RestCallback,queryparams?:[string, string][])
    {
        genericClient.callHttp(this.api,"GET",[],callback,undefined,undefined,queryparams);

    }

    public updateDialog(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api,"PATCH",[['Content-Type', 'application/json']],callback,undefined,body);

    }

    public createDialog(callback:RestCallback,body:string)
    {
        genericClient.callHttp(this.api,"POST",[['Content-Type', 'application/json']],callback,undefined,body);

    }



}
const apiDialogs = new ApiDialogs();

export {apiDialogs};