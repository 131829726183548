export const backBubleMe:string='background.body';
export const backBubleThem:string='primary.softActiveColor';


 export const fontBubleMe:string='var(--joy-palette-text-primary)' ;
export const fontBubleThem:string='neutral.softBg';


//'var(--joy-palette-common-white)'//? 'var(--joy-palette-text-primary)'
//'var(--joy-palette-common-white)'//?'var(--joy-palette-text-primary)' 

//https://mui.com/joy-ui/customization/theme-colors/