import {KeyboardArrowRight} from "@mui/icons-material";
import MediationIcon from '@mui/icons-material/Mediation';
import {List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Sheet} from "@mui/joy";
import {observer} from "mobx-react-lite";
import {UserSType, UserType, UserTypeLight} from "../../../types/UserType";
import {useTranslation} from "react-i18next";
import React from "react";
import currentInfoStore from "../../../store/CurrentInfoStore";
import {apiUsers} from "../../../net/rest/ApiUsers";
import {EditDialog, EditDialogDescriptor, initEditDialogDescriptor} from "../../../components/EditDialog";
import {identity, ustring} from "../../../types/Alias";
import {CaptionPanel} from "../../../components/CaptionPanel";
import {EditButtonsPanel} from "../../../components/EditButtonsPanel";
import {ListDecorator} from "../../../components/ListDecorator";
import usersStore from "../../../store/UsersStore";
import {TeamSType, TeamType} from "../../../types/TeamType";
import {apiTeams} from "../../../net/rest/ApiTeams";
import teamStore from "../../../store/TeamStore";
import docsStore from "../../../store/DocsStore";
import {DocSTypeService, DocType, DocTypeService} from "../../../types/DocsType";
import {apiDocs} from "../../../net/rest/ApiDocs";
import KeyIcon from '@mui/icons-material/Key';
import {TypesOfUsers} from "../../../store/Enumerations";

const LeftPanel = observer(() => {

    const {t} = useTranslation();

    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [showStatusLeft, setShowStatusLeft] = React.useState<boolean>(true);
    //Dialogs state
    const [dlgDescriptor, setDlgDescriptor] = React.useState<EditDialogDescriptor>(initEditDialogDescriptor(handlResultDialog));

    //Dialogs close
    function closeDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            return {anchor: undefined, visible: false, elements: [], handlResult: reset.handlResult};
        });
    }

    //Dialogs show
    function showDialog() {
        setDlgDescriptor((reset: EditDialogDescriptor) => {
            reset.visible = true;
            return {...reset};
        });
    }

    function handlResultDialog(anchor: identity | undefined, isapplied: boolean, result?: Map<string, ustring>) {
        closeDialog();
        const selecledUser = usersStore.getUserByID(usersStore.SelectedIdUser);
        if (isapplied) {

            switch (anchor) {
                case "ADD":
                    if (!result?.get("BotName")) {
                        currentInfoStore.setInfo(t('inputBotName'), "ERROR");
                        break;
                    }
                    let newUserAudit = usersStore.getUserByName(result?.get("BotName"));
                    if (newUserAudit !== undefined) {
                        currentInfoStore.setInfo(result?.get("BotName") + " - " + t("exists"), "ERROR");
                        break;
                    }
                    setShowStatusLeft(true);

                    const userTemplate: UserType = {
                        userid: -1,
                        userds: result?.get("BotName") || "NoName",
                        active: 1,
                        usertypeid: TypesOfUsers.Bots,
                        teams: [...(result?.get("teamid")) ? [{teamid: Number(result?.get("teamid")), teamds: ""}] : []]
                    }
                    apiUsers.createUser(
                        {
                            sucess: (data: UserType) => {
                                setShowStatusLeft(false);
                                usersStore.addUser(data)

                            },
                            error: (data: any,) => {
                                setShowStatusLeft(false);
                                currentInfoStore.setInfo("Bots " + data, "ERROR");
                            }

                        },
                        JSON.stringify(userTemplate)
                    );

                    break;
                case "UPD":
                    if (!result?.get("BotName")) {

                        break;
                    }
                    let changeUserAudit = usersStore.getUserByName(result?.get("BotName"));
                    if (changeUserAudit !== undefined) {
                        currentInfoStore.setInfo(result?.get("BotName") + " - " + t("exists"), "ERROR");
                        break;
                    }

                    if (selecledUser) {

                        setShowStatusLeft(true);

                        const userTemplate: UserTypeLight = {
                            userid: selecledUser.userid,
                            userds: result?.get("BotName") || "NoName",
                            active: selecledUser.active,
                            usertypeid: selecledUser.usertypeid
                        }

                        apiUsers.updateUser(
                            {
                                sucess: (data: UserType) => {
                                    setShowStatusLeft(false);
                                    selecledUser.userds = data.userds;
                                },
                                error: (data: any) => {
                                    setShowStatusLeft(false);
                                    currentInfoStore.setInfo("Bots " + data, "ERROR");
                                },

                            },
                            JSON.stringify(userTemplate)
                        );
                    }

                    break;
                case "DEL":

                    if (selecledUser) {

                        const userTemplate: UserTypeLight = {
                            userid: selecledUser.userid,
                            userds: selecledUser.userds,
                            active: 0,
                            usertypeid: selecledUser.usertypeid
                        }

                        apiUsers.deleteUser(
                            {
                                sucess: (data: UserType) => {
                                    setShowStatusLeft(false);
                                    usersStore.clearSelection()
                                    usersStore.deleteUser(data);
                                },
                                error: (data: any) => {
                                    setShowStatusLeft(false);
                                    currentInfoStore.setInfo("Bots " + data, "ERROR");
                                },
                            },
                            JSON.stringify(userTemplate)
                        );
                    }
                    break;
                default:
                    break;
            }
        }

    }


    //Модальное окно добавления пользователя
    function addRecord() {
        dlgDescriptor.title = t("Add_BotTitle");
        dlgDescriptor.apply = t("Button_ADD");
        dlgDescriptor.cancel = t("Button_Cancel");
        dlgDescriptor.elements = [
            {format: "INPUT", key: "BotName", title: t("Elements_Call_Title")},
            {
                format: "SELECT", key: "teamid", title: t('defaultteam'), currentValue: "",
                source: [
                    {code: "", value: ""},
                    ...teamStore.getTeams().map((team: TeamType) => {
                        return {code: String(team.teamid), value: team.teamds}
                    }
                    )]
            },
        ];

        dlgDescriptor.anchor = "ADD";
        showDialog();
    }

    //Updating
    function updateRecord() {
        const selecledUser = usersStore.getUserByID(usersStore.SelectedIdUser);
        if (selecledUser) {
            dlgDescriptor.title = t("Edit_BotTitle");
            dlgDescriptor.apply = t("Button_change");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.elements = [{format: "INPUT", key: "BotName", title: t("Elements_Call_Title"), currentValue: selecledUser.userds}];
            dlgDescriptor.anchor = "UPD";
            showDialog();
        }
    }

    //Deleting
    function deleteRecord() {
        const selecledUser = usersStore.getUserByID(usersStore.SelectedIdUser);
        if (selecledUser) {
            dlgDescriptor.title = t("Delete_Title");
            dlgDescriptor.cancel = t("Button_Cancel");
            dlgDescriptor.apply = t("Button_Delete");
            dlgDescriptor.text = `${t("Delete_Text")} ${selecledUser.userds} ?`;
            dlgDescriptor.elements = [];
            dlgDescriptor.anchor = "DEL";
            showDialog();
        }
    }

    function updateUsersAsBots() {
        setShowStatusLeft(true);
        usersStore
            .clearSelection()
            .deleteUsersBy((value: UserType) => value.usertypeid === TypesOfUsers.Bots)
            .deleteUsersBy((value: UserType) => value.usertypeid === TypesOfUsers.BotFather);

        apiUsers.getUsers({
            sucess: (data: UserSType) => {
                data.content.forEach((value: UserType) => {
                    usersStore.addUser(value);
                })
                updateUsersAsBotsWelcome()
            },
            error: (data: any) => {
                currentInfoStore.setInfo("Bots " + data, "ERROR");
                setShowStatusLeft(false);
            }
        }, [["usertypeid", String(TypesOfUsers.Bots)], ["pagenumber", "0"], ["pagesize", "999999"]]);
    }

    function updateUsersAsBotsWelcome() {

        apiUsers.getUsers({
            sucess: (data: UserSType) => {
                data.content.forEach((value: UserType) => {
                    usersStore.addUser(value);
                })
                setShowStatusLeft(false);
            },
            error: (data: any) => {
                currentInfoStore.setInfo("Welcome BOT" + data, "ERROR");
                setShowStatusLeft(false);
            }
        }, [["usertypeid", String(TypesOfUsers.BotFather)], ["pagenumber", "0"], ["pagesize", "999999"]]);
    }




    function updateTeams_silent() {
        apiTeams.getTeams({
            sucess: (data: TeamSType): void => {

                data.content.forEach((value: TeamType) => {
                    teamStore.addTeam(value);
                })
            }, error: (data: any): void => {
                setShowStatusLeft(false);
                currentInfoStore.setInfo("Teams refill" + data, "ERROR");
            }
        },
            [["pagesize", "1000"], ["pagenumber", "0"]]
        );
    }



    function updateDocs_silent() {

        apiDocs.getDocs({
            sucess: (data: DocSTypeService): void => {
      
              data.content.forEach((value: DocTypeService) => {
                docsStore.addDoc(value);
              });
    
            }, error: (data: any): void => {
                setShowStatusLeft(false)
                currentInfoStore.setInfo("Docs refill" + data, "ERROR");
            }
          },
            [["pagesize", "99999"], ["pagenumber", "0"]]
      
          );

    }



    React.useEffect(() => {
        updateUsersAsBots();
        updateTeams_silent();
        updateDocs_silent();
        return () => {

        };
    }, [refresh]);

    return (
        <>
            <Sheet
                sx={{
                    width: 300,
                    height: 'calc(100dvh - var(--Header-height))',
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >

                <CaptionPanel caption={t("title_bots")}
                    status={() => showStatusLeft}
                    refresh={() => setRefresh(!refresh)} />


                <ListDecorator>
                    <List size="sm">

                        {usersStore.Display((value) => [TypesOfUsers.Bots, TypesOfUsers.BotFather].indexOf(value.usertypeid) >= 0)
                            .map((value: UserType) =>
                            (
                                <ListItem
                                    variant={((usersStore.SelectedIdUser === value.userid)) ? "outlined" : "plain"}
                                    sx={{backgroundColor: (usersStore.SelectedIdUser === value.userid) ? "primary.softHoverBg" : "inherit"}}
                                    key={value.userid} >
                                    <ListItemButton variant="plain"
                                        onClick={() => {
                                            usersStore.SelectedName = value.userds;
                                            usersStore.SelectedIdUser = value.userid;
                                        }}>
                                        <ListItemDecorator>

                                            {(value.usertypeid === TypesOfUsers.BotFather)
                                                ? (<KeyIcon />)
                                                : (<MediationIcon />)}


                                        </ListItemDecorator>
                                        {/* <ListItemContent>{value.ds_name} ({value.teams.length})</ListItemContent> */}
                                        <ListItemContent>{value.userds}</ListItemContent>
                                        {(usersStore.SelectedIdUser === value.userid) && (<KeyboardArrowRight />)}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </ListDecorator>
                <EditButtonsPanel add={addRecord} update={updateRecord} delete={deleteRecord} />
            </Sheet>
            <EditDialog descriptor={dlgDescriptor} />
        </>

    );
});


export {LeftPanel};